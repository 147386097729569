@import "../../css/constants";
// .introduction,


.research-page-body {
  height: 100%;
  // margin-top: 20px;
  padding: 0;
  overflow: hidden;
  font-family: $primary-font-family;
  // padding-left: 25vh;
  // padding-right: 25vh;

}

.research-style {

  padding-left: 15vw;
  padding-right: 15vw;
  font-family: $primary-font-family;


  ul {
    list-style-type: disc; // This adds the dot before each list item
    padding-left: 20px; // Ensures indentation of list items
  }

  .subpage-heading {
    font-family: $primary-font-family;;
    font-size: 32px;
    color: #096278;
    margin-bottom: 30px;
    margin-top: 50px;
    font-weight: 500;
  }

  .research-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 10px; /* Adjust gap as needed */
  }
  

  // .research-subheading p {
  //   font-family: $primary-font-family;
  
  //   font-size: 20px;
  //   color: #333333;
  //   margin-bottom: 50px;
  //   margin-left: 0;
  // }

  .research-subheading {
    font-family: $primary-font-family;

    // font-size: 20px;

    // margin-left: 0;

    h3 {
          margin-bottom: 20px;
          color: map-get($colors, title-green);
        }
      
        h4 {
          margin-bottom: 20px;
          color: map-get($colors, sub-title-green);
          margin-bottom: 1vh;
        }
  }

  .research-subheading img {
    float: left;
    margin-right: 25px;
    border-radius: 8px;

    width: 350px; /* Fixed width */
    height: 300px; /* Fixed height */
    object-fit: cover; /* This ensures the image covers the area without losing its aspect ratio. */
  }

  p {
    font-family: "Arial" !important;
    font-size: 18px;
    color: #333333;
    line-height: 1.6;
    font-family: $primary-font-family;
    font-weight: 300;
  }
}

// .title {
//   margin-bottom: 2vh;
//   color: map-get($colors, title-green);
// }

@media only screen and (max-width: 1024px) {

  .research-style {

    // padding-left: 15vw;
    // padding-right: 15vw;
    font-family: $primary-font-family;
    // width: 80%;
  
  
    ul {
      list-style-type: disc; // This adds the dot before each list item
      padding-left: 20px; // Ensures indentation of list items
    }
  
    .subpage-heading {
      font-family: $primary-font-family;;
      font-size: 32px;
      color: #096278;
      // margin-bottom: 30px;
      margin-top: 50px;
      font-weight: 500;
      width: 110% ;
    }
  
    .research-container {
      display: flex;
      flex-wrap: wrap;
      margin-left: -20px;
      width: 50%x;

      gap: 10px; /* Adjust gap as needed */
     
    }
    


  
    .research-subheading {
      font-family: $primary-font-family;
  
  
      h3 {
            margin-bottom: 20px;
            color: map-get($colors, title-green);
          }
        
          h4 {
            margin-bottom: 20px;
            color: map-get($colors, sub-title-green);
            margin-bottom: 1vh;
          }
    }
  
    .research-subheading img {
      float: left;
      margin-right: 25px;
      border-radius: 8px;
  
      width: 300px; /* Fixed width */
      height: 250px; /* Fixed height */
      object-fit: cover; /* This ensures the image covers the area without losing its aspect ratio. */
    }
  
    p {
      font-family: "Arial";
      font-size: 18px;
      color: #333333;
      line-height: 1.6;
      font-family: $primary-font-family;
      font-weight: 300;
    }
  }
  

}