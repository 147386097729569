@import "../css/constants.scss";

.mic-button {
  color: map-get($colors, #dad8d6);
  border: none; 
  border-radius: 50%; 
  width: 50px; 
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-left: 10px;
  margin-right: 10px;

  &:hover {
    background-color: #b3b6ba; 
  }

  &:focus {
    outline: none;
  }
}

.send-button {
  color: map-get($colors, #dad8d6) !important;
  border: none; 
  border-radius: 50%; 
  width: 50px; 
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-left: 10px;
  margin-right: 10px;

  &:hover {
    background-color: #b3b6ba; 
  }

  &:focus {
    outline: none;
  }
}


.mic-button-headphone {
  color: map-get($colors, #dad8d6);
  border: none; 
  border-radius: 50%; 
  width: 50px; 
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-left: 10px;
  margin-right: 10px;

  &:hover {
    background-color: #b3b6ba; 
  }

  &:focus {
    outline: none;
  }
}


/* SpinningCircle.css */
.spinning-circle-container {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  z-index: 1000; /* Ensure it appears above other elements */
  // background-color:  map-get($colors, #dad8d6); 
  background-color:  map-get($colors, background-blue);
  border-radius: 10px;
  padding:10px;
}

.spinning-circle {
  width: 100px;
  height: 100px;
  border: 10px solid #f3f3f3;
  border-top: 10px solid #3498db;
  border-radius: 50%;
  animation: spin 2s linear infinite;
  margin: 0 auto;
  z-index: 1000;
  // background-color: #7c7a7a; 
}

.start-speaking {
  margin-top: 10px;
  font-size: 18px;
  color: #ffffff;
}

.volume-meter {
  width: 20px;
  height: 100px;
  background-color: #ddd;
  margin: 10px auto;
  position: relative;
  overflow: hidden;
  border-radius: 10px;
}

.volume-level {
  position: absolute;
  bottom: 0;
  width: 100%;
  background-color: #3498db;
  transition: height 0.1s ease-out;
}

.custom-tooltip {
  font-family: $primary-font-family;
  font-size: 14px;
  color: #333;
  background-color: #fff;
  border: 1px solid #ccc;
  padding: 10px;
  border-radius: 4px;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}


@media only screen and (max-width: 1024px) {

  .mic-button-headphone {
   display: none;
  }

  .mic-button {
 display: none;
  }

}