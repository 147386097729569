@import "../../css/constants";



.language-dropdown {
  // Style the dropdown container
  position: relative;
  width: 400px;  // Adjust the width as per your design
  margin: 10px 0;
  margin-bottom: 30px; 


  .language-dropdown-label {
    color: map-get($colors, textbox-white); // Set the label text color
    font-size: 16px; // Optional: adjust the font size
    margin-bottom: 10px; // Add some space between the label and the dropdown
  }


  select {
    // Style the select element
    margin-top: 20px;
    width: 60%;
    padding: 10px;
    font-size: 16px;
    border-radius: 8px;
    // border: 1px solid #ccc;
    background-color: map-get($colors, textbox-white-light);
    color: map-get($colors, textbox-white);  // Text color
    appearance: none;  // Remove the default arrow in some browsers
    cursor: pointer;

    &:hover {
      border-color: #888;  // Change border color on hover
    }

    &:focus {
      outline: none;
      border-color: #007bff;  // Focus border color
      box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);  // Add a shadow to indicate focus
    }
  }

  // Add custom arrow for the dropdown
  &:after {
    content: '▼';  // Custom arrow symbol
    font-size: 12px;
    // color: #333;
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    pointer-events: none;
  }
}


.modal {
  /* Existing modal styles */
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  /* Existing content styles */
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  /* Add or modify the width here: */
  width: 100%;    /* Adjust as needed */
  max-width: 1200px; /* Limit the max-width for better responsiveness */
}


@media only screen and (max-width: 1024px) {

.download-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
 
}

.popup-content {
  background: #ffffff;
  border-radius: 10px;
  padding: 20px;
  text-align: center;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  max-width: 400px;
  width: 80%;

}

.popup-content p {
  font-size: 18px;
  color: #333333;
  margin-bottom: 20px;
  font-family: $primary-font-family;
  text-align: center;
}

.download-button,
.continue-button {
  background-color: #007aff; /* iOS blue color */
  color: #ffffff;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  font-size: 14px;
  cursor: pointer;
  margin: 5px;
  transition: background-color 0.3s ease;
  font-family: $primary-font-family;
}

.download-button:hover {
  background-color: #005bb5; /* Darker blue on hover */
}

.close-button {
  background-color: #cccccc;
  color: #333333;
}

.close-button:hover {
  background-color: #999999;
}
}