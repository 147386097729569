@import "../../css/constants";
.mood-profile-page {
  text-align: center; 
  padding: 20px;
  font-family: $primary-font-family;

  .chart-container {
    display: flex;
    flex-direction: column;
    gap: 20px;
    max-width: 800px; /* Optional, restricts max size for aesthetics */
   margin: 0 auto;
    
  }

  .positive-chart,
  .negative-chart {
    background-color: #f9f9f9;
    padding: 15px;
    border-radius: 8px;
    width: 100%;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  }


  h2 {
    // text-align: center;
    margin-bottom: 30px;
  }

  .button-container {
    margin-bottom: 20px;
    flex-direction: column;
    align-items: center; // Center-align the button and text
   


    .get-mood-button {
      background-color: #007bff; // Default color
      color: #fff;
      border: none;
      padding: 10px 20px;
      font-size: 16px;
      border-radius: 5px;
      cursor: pointer;
      transition: background-color 0.3s;
      width: 200px;

      &:hover {
        background-color: #0056b3;
      }

      &:disabled {
        background-color: #d3d3d3;
        color: #6c757d;
        cursor: not-allowed;
      }
    }

    .description {
      margin-top: 10px;
      font-size: 18px;
      color: #6c757d;
    }

    .get-mood-button.loading {
      background-color: #d3d3d3; // Grey color for loading state
      color: #6c757d; // Optional: slightly darker grey for text
      cursor: not-allowed;
    }
    
  }



.loading-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background: white;
  padding: 20px;
  border-radius: 8px;
  text-align: center;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal {
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  text-align: center;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}




}



.personality-profile-page {
  padding: 20px;
  max-width: 1000px;
  margin: 0 auto;
  font-family: $primary-font-family;
  // background-color:  map-get($colors, background-blue);;

  h2 {
    text-align: center;
    margin-bottom: 30px;
  }

  .info-message {
    display: flex; /* Enable flexbox */
    justify-content: center; /* Center horizontally */
    align-items: center; /* Center vertically (optional, if needed) */
    text-align: center; /* Ensures text alignment within the element */
    margin: 20px 0; /* Optional: Adds vertical spacing */
    font-size: 16px; /* Adjust font size as needed */
    color: #555; /* Optional: Change the text color */
    height: 50px; /* Optional: Ensures the element has height if needed */
  }
 
  .profile-layout {
    display: flex;
    flex-direction: column;
    gap: 40px;

    .single-column {
      text-align: left;

      .chart-container {
        width: 100%; /* Full width */
        max-width: 800px; /* Optional, limit the max size */
        margin: 0 auto;
      }

      h3 {
        font-size: 1.5em;
        margin-bottom: 15px;
        text-align: left; /* Align all headlines to the left */
      }

      .visionary-text {
        background-color: #f9f9f9;
        padding: 15px;
        border-radius: 8px;
        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
        margin-top: 20px;

        p {
          font-size: 1em;
          line-height: 1.6;
        }
      }

      .trait-descriptions {
        margin-top: 20px;
        background-color: #f9f9f9;
        padding: 15px;
        border-radius: 8px;
        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);

        .trait {
          margin-bottom: 20px;

          h3 {
            font-size: 1.2em;
            margin-bottom: 10px;
            text-align: left; /* Align all headlines to the left */
          }

          p {
            font-size: 1em;
            line-height: 1.6;
            text-align: left; /* Align text to the left */
          }
        }
      }
    }
  }
}

.fetch-profile-button {
  background-color: #22caec; /* Primary blue color */
  color: #fff; /* White text */
  font-size: 1rem; /* Adjust font size */
  font-weight: bold;
  padding: 10px 20px; /* Button padding */
  border: none; /* Remove border */
  border-radius: 8px; /* Rounded corners */
  cursor: pointer; /* Pointer cursor on hover */
  transition: background-color 0.3s ease, transform 0.2s ease; /* Smooth hover effect */
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1); /* Subtle shadow */
}

/* Hover and focus styles */
.fetch-profile-button:hover {
  background-color: #1ba6c3; /* Darker blue on hover */
  transform: translateY(-2px); /* Slight lift on hover */
}

/* Disabled state */
.fetch-profile-button:disabled {
  background-color: #cccccc; /* Gray background */
  color: #666666; /* Gray text */
  cursor: not-allowed; /* Show not-allowed cursor */
  box-shadow: none; /* Remove shadow */
}

.info-message {
  font-size: 1rem; /* Adjust the font size */
  color: #555; /* Soft gray color for readability */
  margin-top: 10px; /* Space between the button and the text */
  text-align: center; /* Center-align the text */
  line-height: 1.5; /* Improve readability with line spacing */
  padding: 10px 20px; /* Add some padding for better spacing */
  background-color: #f9f9f9; /* Light background to make it stand out */
  border-radius: 8px; /* Rounded corners for a modern look */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
  max-width: 600px;
}




