@import "../css/constants";

:root {

  --right-msg-bg: rgba(255, 255, 255, 0.582); //plan 1
  --left-msg-bg: rgba(255, 255, 255, 0.804);
  // 
}

.body_chat {
  display: flex;
  justify-content: center;
  align-items: center;
  // height: 100vh;
  background-image: var(--body-bg);
  font-family: $primary-font-family;
  flex: 1;

}

// .mic-button-headphone{
//   border-radius: 50%;
//   border: none;
//   width: 6%;

// }

.chatbot-page {
  display: flex;
  flex-direction: column;
  height: 100vh;
  // margin: 60px;
  // margin-top: 50px;
  overflow: hidden;

  .style-button-containter {
    position: relative;
    font-size: 14px;
    text-align: center;
    bottom: 9vh;
    z-index: 1050;
  }

  .toggle-container {
    position: fixed;
    width: 80px;
    height: 40px;
    border-radius: 20px;
    margin-bottom: 10px;
    right: -145vh;
    bottom: -10vh;
    overflow: hidden;
    font-family: "Arial";
    transition: background-color 0.3s;

    &.mode-compact {
      background-color: #c8d0dd;
    }

    &.mode-wide {
      background-color: #29b5a8;
    }

    .label-left,
    .label-right {
      position: absolute;
      font-weight: bold;
      top: 50%;
      transform: translateY(-50%);
      color: #fff;
      font-size: 14px;
      font-family: "Arial";
    }

    .label-left {
      left: 10px;
    }

    .label-right {
      right: 10px;
    }
  }

//   @media (max-width: 900px) {
//     .toggle-container {
//         display: none;
//     }
// }

  .toggle-theme-btn {
    position: absolute;
    width: 30px;
    height: 30px;
    background-color: white;
    border: none;
    border-radius: 50%;
    transition: 0.3s;
    cursor: pointer;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    &.compact {
      left: calc(100% - 60px);
    }

    &.wide {
      left: calc(100% - 20px);
    }
  }
}
.chatbot-page::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url('../assets/chatbot_bg3.jpg'); 
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  // opacity: 0.7; 
  pointer-events: none; 
  transform: scaleX(-1);
}

.chatbot-page > * {
  position: relative; /* Ensures content is above the background */
  z-index: 1; /* Higher z-index to keep content above the pseudo-element */
}
ct {
  max-width: 800px;
  margin: 50px auto;
}
.msger {
  display: flex;
  flex-flow: column wrap;
  justify-content: space-between;
  // height: calc(100% - 100px);
  // height: calc(100% - 100px);
  height: 100%;
  width: 100%;
  max-width: 1200vh;
  margin: 50px 130px;
  max-height: 80vh;
  border-radius: 5px;
  overflow: hidden;
  flex: 1;

  /* New .compact class adjusting the .msger styling for compact mode */

  .msger-chat {
    flex: 1;
    overflow-y: auto;
    padding: 30px;



    &::-webkit-scrollbar {
      width: 6px;
    }
    &::-webkit-scrollbar-track {
      background:  rgba(255, 255, 255, 0.2);
    }
    &::-webkit-scrollbar-thumb {
      background  :rgba(255, 255, 255, 0.8);
    }
  }
}

.msger-inputarea {
  display: flex;
  padding: 10px;
  

  background:transparent;
  font-family: "Arial";
  justify-content: space-between;


  .msger-input {
    flex: 1;
    padding: 10px;
    resize: none;
    overflow-y: auto;
    background: rgba(255, 255, 255, 0.1);
    color: rgb(255, 255, 255);

    border-radius: 5px;
    height: 35px;
    border: none; // Remove the grey border
    outline: none; 

    &::placeholder {
      color: rgba(253, 253, 253);
      opacity: 1;
    }
 
}
  

.msger-input:focus {
  outline: 2px solid rgba(255, 255, 255, 0.5);
  outline-offset: 2px;
}

  .msger-send-btn {
    margin-left: 10px;
    margin-right: 10px;
    background: rgba(255, 255, 255, 0.8);
    // background: radial-gradient(circle at bottom left, #874CCC, #667BC6);
  
    color: #504f4f;
    cursor: pointer;
    transition: background 0.23s;
    font-weight: bold;
    height: 55px;
    padding: 10px 10px;
    border: none;
    border-radius: 8px;
  }
}

.session-complete-container {
  display: flex;
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  height: 30%; /* Take the full height of the parent or as needed */
  gap: 40px;
}

.session-complete-btn {
  // margin-left: 50px;
  // margin-right: 10px;
  background: #22a699;
  color: #fff;
  cursor: pointer;
  transition: background 0.23s;
  height: 55px;
  padding: 10px 10px;
  border: none;
  font-weight: bold;
  border-radius: 8px;
}

.session-action-btn {
  background: #22a699;
  color: #fff;
  cursor: pointer;
  transition: background 0.23s;
  height: 55px;
  padding: 10px 10px;
  border: none;
  border-radius: 8px;
  font-weight: bold;
}

/*------------- message bubble style ---------- */
.msg {
  display: flex;
  align-items: flex-end;
  margin-bottom: 10px;

  .msg-img {
    width: 60px;
    height: 60px;
    margin-right: 10px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    border-radius: 50%;
  }
}

.msger-chat {
  // background-color: #fcfcfe;
  background: transparent;
}

.msg-bubble {
  max-width: 450px;
  padding: 15px;
  border-radius: 15px;
  background: var(--left-msg-bg) !important;
  margin-top: 20px;
  font-family: "Arial";
  font-size: 16px;
  box-shadow: 0 8px 10px rgba(0, 0, 0, 0.1);

  .msg-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
  }
  .msg-info-name {
    margin-right: 10px;
    font-weight: bold;
  }
  .msg-info-time {
    font-size: 0.85em;
  }
}

.msger.compact .msg-bubble {
  font-size: 16px;
}

.right-msg {
  flex-direction: row-reverse;

  .msg-bubble {
    background: var(--right-msg-bg) !important;
    color: black;
    border-bottom-right-radius: 0;
  }

  .msg-img {
    margin: 0 0 0 10px;
  }
}


.language-dropdown-container {
  position: fixed;
  top: 10px; // Adjust as per your header's height
  right: 20vw;
  z-index: 1000; // Ensure it's above other elements
  font-family: $primary-font-family;

  // .language-select-wrapper {
  //   display: flex; // Enables flexbox
  //   align-items: center; // Vertically centers the items in the container

    .language-label {
        margin-right: 10px; // Adds spacing between label and dropdown
        white-space: nowrap; // Keeps the label on a single line
        color: #1e2246;
        font-weight: bold;
        font-size: 14px;
    }

  .language-dropdown {
    padding: 5px 10px;
    border-radius: 5px;
    font-size: 12px;
    background-color: white;
    border: 1px solid #ccc;
  }

 
  

}

.mute-button-containter{
  position: fixed;
  top: 20px; // Adjust as per your header's height
  right: 35vw;
  z-index: 1000; // Ensure it's above other elements
  font-family: $primary-font-family;

  .mute-button {
    background: none;
    border: none;
    cursor: pointer;
    font-size: 1.5rem; // Adjust size as needed
    display: flex;
    align-items: center;
  }

}
/*------------- api input area ---------- */
.api-inputarea {
  display: flex;
  padding: 10px;
  border-top: var(--border);
  background: #eee;

  * {
    padding: 10px;
    width: 250px;
    border: none;
    border-radius: 3px;
    font-size: 1em;
    font-family: "Arial";
  }

  .api-input {
    flex: 1;
    background: #ddd;
  }

  .api-send-btn {
    margin-left: 10px;
    background: #0678bf;
    color: #fff;
    font-weight: bold;
    cursor: pointer;
    transition: background 0.23s;
  }
  .api-send-btn:hover {
    background: #30c8d9;
  }
}

/*------------- agreement component ---------- */

.agree-tacbox {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f5f5f5;
  border-radius: 8px;
}

.indented-list {
  padding-left: 5vh;
}

.agree-tacbox p,
.agree-tacbox ul {
  margin-bottom: 10px;
}

.checkbox-container {
  display: flex;
  align-items: flex-start;
  margin-bottom: 10px;
}

.checkbox-container input[type="checkbox"] {
  margin-top: 4px;
  flex-shrink: 0;
}

.checkbox-container label {
  margin-left: 20px;
  flex-grow: 1;
  word-wrap: break-word;
  line-height: 1.5;
  margin-right: -30vh;
}

.agree-btn-container {
  text-align: center;
  margin-top: 20px;
}

.agree-btn {
  padding: 10px 20px;
  font-size: 16px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}


/*  rating and feedback */
.ratings img {
  cursor: pointer;
  transition: all 0.5s;
  height: 20px;
  float: left;
}

.ratings img:hover {
  transform: scale(1.3);
}

.rating-img {
  font-size: 0;
}

.feedback-inputarea {
  margin-top: 5px;
}

.feedback-inputarea input {
  height: 20px;
  width: 40%;
}

.send-correct {
  margin-left: 5px;
  background: rgb(229, 227, 225);
  color: #0e0e0e;
  cursor: pointer;
  transition: background 0.23s;
  margin-bottom: 5px;
}

/*Defining the thumb button in feedback form */
.rating-img {
  height: 20px;
  width: auto;
}

.rating-btn {
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
}

.rewrite-textarea {
  width: 500px;
  width: 90%;
}



/* Styling for the typing dots */
.dot-flashing {
  display: inline-block;
  position: relative;
  width: 20px;
  text-align: left;
}

.dot-flashing::before {
  content: '.';
  animation: dot-flashing 1s infinite steps(1, end);
}

.dot-flashing::after {
  content: '..';
  animation: dot-flashing 1s infinite steps(1, end) 0.5s;
}

/* Flashing animation */
@keyframes dot-flashing {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.progress-bar-wrapper {
  display: flex;
  overflow: hidden;

  align-items: center; // Vertically centers the text and the progress bar
  // justify-content: space-between; 
  margin-bottom: 0px;
  padding: 10px 10px;
  // margin: 0 auto;
  overflow: hidden;
  width: 40%;
  margin-top: 10px;
  justify-content: center;
  margin-bottom: 20px;

}
.progress-bar-container {
  background-color: #fffefe; 
  flex: 2; // Adjust as needed to control the width of the progress bar
  border-radius: 10px;  


}


.progress-bar {
  height: 100%;
  background-color: #6a70b8; // Green progress indicator
  text-align: center;
  color: #fff;
  font-size: 14px;
  font-weight: bold;
  line-height: 20px; // Align text vertically
  border-radius: 10px; // Ensure rounded corners
  transition: width 0.3s ease-in-out; // Smooth width transition

  // width: 50px;
}


.progress-message {
  text-align: center;
  font-size: 14px;
  color: #333; // Adjust color as needed
  margin-left: 10px; // Add space if shown beside the description
  font-weight: bold;
  color: #030342; 

}

.progress-bar-info {
  flex: 1; // Ensures the progress bar takes up most of the space

 
}

.progress-description {
  flex: 1; // Adjust as needed
  padding-left: -10px; // Space between the progress bar and the text
  font-size: 14px;
  color: #030342; // Adjust text color as needed
  width: 1400px;
  margin-left: 40px; 
  // text-shadow: 2px 2px 8px rgba(255, 255, 255, 0.8);
  
}

.progress-bar-text-wrapper{
  width: 150px;
  display: flex;
  height: 40px;
  // padding: 0px 10px;
  width: 80%;
  align-items: center; /* Vertically centers the content */
  justify-content: center;
  margin-top: 10px;
  margin-left: 50px;
  position: fixed;
  font-family: $primary-font-family;
  z-index: 1000;
  background-color: rgba(254, 254, 254, 0.4);
  padding: 20px;
  border-radius: 8px;



}

.close-button {
  position: absolute;
  top: 0px;
  right: 0px;
  width: 20px; /* Set width */
  height: 20px; /* Set height */
  background-color:  #6a70b8;
  color: white !important ; 
  font-size: 16px;
  cursor: pointer;
  padding: 0;
  border-radius: 50%;
  display: flex; 
  align-items: center; 
  justify-content: center; 
  transition: background-color 0.2s ease-in-out;
  border: none;
  outline: none;
}

.close-button:hover {
  background-color: #f0f0f0;
  color: #000;
}

.close-button:focus {
  outline: none;
}

// .modal-backdrop {
//   position: fixed;
//   top: 0;
//   left: 0;
//   width: 100%;
//   height: 100%;
//   background-color: rgba(0, 0, 0, 0.5); /* Dark transparent background */
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   z-index: 1000;
// }



// .modal-content {
//   background-color: #ffffff; /* White background for the modal */
//   padding: 20px;
  
//   border-radius: 15px; /* Rounded corners */
//   box-shadow: 0 4px 15px rgba(170, 153, 153, 0.2); /* Subtle shadow for depth */
//   text-align: center;
//   width: 90%;
//   max-width: 400px; /* Set a max width */
//   animation: fadeIn 0.3s ease; /* Smooth fade-in animation */

// }
.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #f9f9f9;
  // background-color: rgba(0, 0, 0, 0.6); /* Darker transparent background for better focus */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1050; /* Slightly higher z-index for stacking context */
}

.modal-content {
  // background-color: #f9f9f9; /* Light grayish white for a softer appearance */
  padding: 24px; /* Slightly larger padding for better spacing */
  border-radius: 12px; /* Rounded corners for a modern look */
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.2); /* More prominent shadow for depth */
  text-align: center;
  width: 90%;
  max-width: 360px; /* Refined max width for better responsiveness */
  font-family: $primary-font-family; /* Use a clean, modern font */
  font-size: 16px; /* Standard font size for readability */
  color: #3d3d3d; /* Dark gray text for better readability */
  animation: scaleIn 0.3s ease-in-out; /* Scale-in animation for smooth appearance */

}


// .modal-content  {
//   font-size: 1.5rem;
//   color: #ffffff; /* Darker text color for better readability */
//   margin-bottom: 20px;
//   font-weight: 300 !important;
// }

.modal-content button {
  background-color: #3498db; /* Blue color for buttons */
  color: white;
  font-size: 1rem;
  padding: 10px 20px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  margin: 5px;
  transition: background-color 0.3s ease;
}

.modal-content button:hover {
  background-color: #2980b9; /* Darker blue on hover */
}

.modal-content button:active {
  background-color: #1f5f8a; /* Even darker blue on click */
}



.voice-mode-info {
  text-align: center;
  font-family: $primary-font-family;
  padding: 10px;
  background-color: transparent;
  color: #ffffff;
  font-size: 16px;
  z-index: 200;
  top:20px;
  border: 2px solid #ccc;
  border-radius: 5px;
  border-color: map-get($colors, textbox-white-light); 
  width: 900px;
  margin: 0 auto;

   /* Add a glowing box-shadow */
   box-shadow: 0 0 10px rgba(235, 236, 238, 0.5);
}

.countdown-timer2 {
  display: flex;
  justify-content: center; /* Centers the text horizontally */
  align-items: center; /* Centers the text vertically */
  text-align: center; /* Aligns the text in the center */
  margin-top: 10px; /* Optional: Adds spacing above the countdown */
  font-size: 14px; /* Adjusts the font size */
  font-weight: bold; /* Makes the text bold */
  color: #333; /* Sets the text color */
}

.close-button-voice-mode {
  position: absolute;
  top: -10px;
  right: -10px;
  background: #f0f0f0;
  border: 2px solid #ccc;
  border-radius: 5px;
  border-color: map-get($colors, textbox-white-light); 
  font-size: 16px;
  cursor: pointer;
  color: #494949;
  border-radius: 50%;
  width: 30px; /* adjust as needed */
  height: 30px; /* adjust as needed */
   
 
}



.highlight-profile {
  background-color: map-get($colors, background-blue ); /* Golden highlight */
  padding: 2px 5px;
  border-radius: 3px;
  font-weight: bold;
  color: #ffffff;
}

.close-button-voice-mode:hover {
  color: #4538f9;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: scale(0.95);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

//mask layer in voice mode
.chatbot-container {
  position: relative;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.9); /* Black with 50% opacity */
  z-index: 10; /* Ensure it appears above other elements */
  display: flex;
  justify-content: center;
  align-items: center; 
  pointer-events: none; 
}

.msger-inputarea {
  position: relative;
  z-index: 20; /* Ensure the form appears above the overlay */
}


.overlay-avatar {
  width: 200px; /* Adjust the size of the avatar */
  height: 200px;
  border-radius: 50%; /* Make the image circular */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Add some shadow for depth */
  border: 3px solid white; /* Add a border for better visibility */
  transform: translateX(-200px);

}

.thinking-text {
  position: absolute;
  top: 50%; /* Adjust based on your design */
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 1.2rem; /* Adjust font size */
  color: white; /* Adjust color */
  text-align: center;
  z-index: 220;
  // background-color: rgba(255, 255, 255, 0.8); /* Optional: semi-transparent background */
  padding: 10px 20px;
  border-radius: 8px; /* Rounded corners */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Optional: subtle shadow */
}

@media only screen and (max-width: 1024px) {

  html, body {
    margin: 0;
    padding: 0;
    height: 100%;
    overflow: hidden;
  }

  .body_chat {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    font-family: "Arial";
    margin: 0;
    padding: 0;
    // position: relative;


  }

  .mic-button-headphone {
    display: none;
  
  }
  
  .chatbot-page {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
   overflow: hidden;
   margin: -100px;
   overflow-x: hidden;


  //  padding-left: -30px;
    // margin: -100px;
    // width: 100%;

    .button-containter {
      position: relative;
      font-size: 14px;
      text-align: center;
      bottom: 9vh;
      z-index: 1050;
    }

    .toggle-container {
      position: relative;
      width: 80px;
      height: 40px;
      border-radius: 20px;
      margin-bottom: 10px;
      right: -145vh;
      bottom: -10vh;
      overflow: hidden;
      font-family: "Arial";
      transition: background-color 0.3s;

      &.mode-compact {
        background-color: #c8d0dd;
      }

      &.mode-wide {
        background-color: #29b5a8;
      }

      .label-left,
      .label-right {
        position: absolute;
        font-weight: bold;
        top: 50%;
        transform: translateY(-50%);
        color: #fff;
        font-size: 14px;
        font-family: "Arial";
      }

      .label-left {
        left: 10px;
      }

      .label-right {
        right: 10px;
      }
    }

    .toggle-theme-btn {
      position: absolute;
      width: 30px;
      height: 30px;
      background-color: white;
      border: none;
      border-radius: 50%;
      transition: 0.3s;
      cursor: pointer;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      &.compact {
        left: calc(100% - 60px);
      }

      &.wide {
        left: calc(100% - 20px);
      }
    }
  }

  .chatbot-page::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 120%;

    // opacity: 0.7; 
    pointer-events: none; 
    transform: scaleX(-1);
  }
  
  .chatbot-page > * {
    position: relative; /* Ensures content is above the background */
    z-index: 1; /* Higher z-index to keep content above the pseudo-element */
  }



  .msger.compact {
    max-width: 80vh;
    margin: 50px auto;
  }

  .msger {
    display: flex;
    flex-flow: column wrap;
    justify-content: space-between;
    // height: 100vh;
    // width: 100%; /* Ensure it takes up the full width */
    // max-width: 100%; 
    min-height: 100vh;
    border: var(--border);
    background: var(--msger-bg);
    overflow: hidden;
    // padding: 20 px;
    z-index: 2;

    /* New .compact class adjusting the .msger styling for compact mode */

    .msger-header {
      position: fixed;
      font-size: medium;
      justify-content: space-between;
      // padding: 10px;
      text-align: center;
      border-bottom: var(--border);
      // background: #eee;
      background:transparent;
      // color: #07b2d9;
      font-family: "Arial";
      font-size: 18px;
      z-index: 10;
    }

    .msger-chat {

      flex: 1;
      overflow-y: auto;
      // width: 100%; 
      padding: 0 0px;


      &::-webkit-scrollbar {
        width: 6px;
      }
      &::-webkit-scrollbar-track {
        background: #ddd;
      }
      &::-webkit-scrollbar-thumb {
        background: #bdbdbd;
      }
    }
  }

  .msger-inputarea {
    display: flex;
    // padding: 10px;
    border: none;
    background:transparent;
    font-family: "Arial";
    justify-content: space-between;
    z-index:1000;
    padding-top: 40px;

    // min-height: -10%;


    .msger-input {
      flex: 1;
      padding: 10px;
      resize: none;
      overflow-y: auto;
      border: none;
      border-radius: 10px;
      background: rgba(255, 255, 255, 0.2);

      &::placeholder {
        color: map-get($colors, button-text-grey);
        opacity: 1;
      }
   
    }

    .msger-input:focus {
      outline: 2px solid rgba(255, 255, 255, 0.5);
      outline-offset: 2px;
    }


    .msger-send-btn {
      margin-left: 10px;
      margin-right: 10px;
      // background: #22a699;
      background: map-get($colors, #dad8d6);
      color: #fff;
      font-weight: bold;
      cursor: pointer;
      transition: background 0.23s;
      height: 55px;
      padding: 10px 10px;
      border: none;
      border-radius: 8px;
      box-shadow: 2px 4px 6px rgba(0, 0, 0, 0.3);
   
      
    }
  }
  .tacbox {
    display: block;
    padding: 2em;
    //margin: 2em;
    //border: 3px solid #ddd;
    background-color: #eee;
    // max-width: 800px;
    width: 80%;
    border-radius: 8px;
    font-size: 2vh;
    font-family: Arial;
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 10vh;

    p {
      font-family: "Arial";
      font-size: 18px;
    }

    .agree-btn {
      margin-top: 10px;
      background: #0678bf;
      color: #fff;
      width: 5em;
      height: 2em;
      border: none;
      font-weight: bold;
      cursor: pointer;
      transition: background 0.23s;
      border-radius: 8px;
    }
  }
}

::-webkit-scrollbar {
  width: 6px;
}
::-webkit-scrollbar-track {
  background: #ddd;
}
::-webkit-scrollbar-thumb {
  background: #bdbdbd;
}

/*------------- message bubble style ---------- */
.msg {
  display: flex;
  align-items: flex-end;
  margin-bottom: 10px;

  .msg-img {
    width: 40px;
    height: 40px;
    margin-right: 10px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    border-radius: 50%;
  }
}

.msger-chat {
  // background-color: #fcfcfe;
  background: transparent;
}

.msg-bubble {
  max-width: 75vw;
  padding: 15px;
  border-radius: 15px;
  // background: var(--left-msg-bg);
  background: rgba(255, 255, 255, 0.3);
  margin-top: 7vh;
  font-family: "Arial";
  font-size: 16px;
  box-shadow: 0 8px 10px rgba(0, 0, 0, 0.1);

  .msg-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
  }
  .msg-info-name {
    margin-right: 10px;
    font-weight: bold;
  }
  .msg-info-time {
    font-size: 0.85em;
  }
}

.msger.compact .msg-bubble {
  font-size: 16px;
}

.right-msg {
  flex-direction: row-reverse;

  .msg-bubble {
    // background: var(--right-msg-bg);
    background: rgba(255, 255, 255, 0.8);
    color: black;
    // color: black;
    border-bottom-right-radius: 0;
  }

  .msg-img {
    margin: 0 0 0 10px;
  }
}

/*------------- api input area ---------- */
.api-inputarea {
  display: flex;
  padding: 10px;
  border-top: var(--border);
  background: #eee;

  * {
    padding: 10px;
    width: 250px;
    border: none;
    border-radius: 3px;
    font-size: 1em;
    font-family: "Arial";
  }

  .api-input {
    flex: 1;
    background: #ddd;
  }

  .api-send-btn {
    margin-left: 10px;
    background: #0678bf;
    color: #fff;
    font-weight: bold;
    cursor: pointer;
    transition: background 0.23s;
  }
  .api-send-btn:hover {
    background: #30c8d9;
  }
}

/*------------- agreement component ---------- */

/*  rating and feedback */
.ratings img {
  cursor: pointer;
  transition: all 0.5s;
  height: 20px;
  float: left;
}

.ratings img:hover {
  transform: scale(1.3);
}

.rating-img {
  font-size: 0;
}

.feedback-inputarea {
  margin-top: 5px;
}

.feedback-inputarea input {
  height: 20px;
  width: 40%;
}

.send-correct {
  margin-left: 5px;
  background: rgb(229, 227, 225);
  color: #0e0e0e;
  cursor: pointer;
  transition: background 0.23s;
  margin-bottom: 5px;
}

/*Defining the thumb button in feedback form */
.rating-img {
  height: 20px;
  width: auto;
}

.rating-btn {
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
}

.rewrite-textarea {
  width: 500px;
  width: 90%;
}

/*------------- profile page, summary container -----------*/
.profile-background {
  // background: linear-gradient(100deg, #07b2d9, #30c8d9 40%, #f2dec4 100%);
  background: #171c3e;
  min-height: 100vh;
  overflow-x: hidden;
  

  .wait-list-text p {
    position: absolute; /* Change to absolute */
    top: 50%; /* Change from bottom to top */
    left: 50%;
    transform: translate(-50%, -50%) scale(0.8);
    font-size: 25px;
    font-family: "Arial";
    text-align: center;
    color: #ffffff;
  }

  .summary-form-container {
    margin-top: 5vh;
    max-width: 1400px;
    //height: 400vh;
    min-height: 100vh;
    overflow-y: auto;
    margin-left: auto; /* Centers the container horizontally */
    margin-right: auto;

    p {
      color: #bdbdbd;
      

    }

    .summary-container {
      margin: 10px;
      margin-top: auto;
      margin-bottom: 10px;
      // margin-left: 100px;
      // margin-right: 600px;
      // border: 1px solid #ffe0d1;
      border-radius: 5px;
      padding: 30px;
      background-color: #1e2246;

      .summary-header {
        cursor: pointer;
        font-weight: bold;
        font-family: "Arial";
      }
    }
    .summary-text {
      font-size: 16px;
      margin-top: 3vh;
      margin-left: 2vh;
      font-family: "Helvetica";
      color: #cac8c8;
      line-height: 2.5vh;
    }

    .summary-time {
      color: #ffffff;
    }

    p {
      font-size: 20px;
      margin-left: 30%;
    }
  }

  .summary-btn-container {
    display: flex;
    justify-content: center;
    // align-items: left;
    gap: 50px;
    // margin-right: 700px;
    // margin-top: 50px;

    .summary-btn {
      margin-top: 100px;
      background: #4356d6;
      color: #fff;
      font-weight: bold;
      cursor: pointer;
      transition: background 0.23s;
      height: 45px;
      padding: 10px 10px;
      border-radius: 10px;
      border: none;
      box-shadow: none;
      font-family: "Arial";
    }
  }
}

.credits-tab {
  // position: relative;
  margin-top: 20px;
  // margin-right: 200px;
  // margin-left: 1000px;
  background-color: #ffffff;
  padding: 10px;
  border-radius: 8px;
  // box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  max-width: 200px;
  margin-left: auto; /* Centers the container horizontally */
  margin-right: auto;

  .font {
    color: white;
    font-size: 18px;
    line-height: 5vh;
    font-family: "Helvetica";
  }
}

