.change-voice-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  font-family: Arial, sans-serif;
  
  .page-header {
    text-align: center;
    margin-bottom: 20px;

    h1 {
      font-size: 2rem;
      margin-bottom: 10px;
    }

    p {
      font-size: 1rem;
      color: #555;
    }
  }

  .voice-container {
    width: 100%;
    max-width: 800px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    background-color: #f3f5fc;
   

    .voice-group {
      border: 1px solid #ddd;
      border-radius: 8px;
      padding: 15px;
      background-color: #fdfdfd;
     

      h2 {
        margin-bottom: 10px;
        font-size: 1.5rem;
        color: #333;
      }

      .voice-list {
        display: flex;
        flex-wrap: wrap;
        gap: 15px;

        .voice-item {
          border: 1px solid #ccc;
          border-radius: 8px;
          padding: 10px;
          text-align: center;
          width: 150px;
          box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
          transition: transform 0.2s;

          &:hover {
            transform: scale(1.05);
          }

          p {
            font-size: 1rem;
            margin-bottom: 10px;
          }

          .play-button,
          .select-button {
            border: none;
            background-color: #007BFF;
            color: white;
            padding: 5px 10px;
            margin: 5px 0;
            border-radius: 4px;
            cursor: pointer;
            font-size: 0.9rem;

            display: flex;
            align-items: center;
            justify-content: center;

            &:hover {
              background-color: #0056b3;
            }
          }

          &.selected {
            border-color: #007BFF;
            background-color: #f0f8ff;
          }
        }
      }
    }
  }

  .page-footer {
    text-align: center;
    margin-top: 30px;

    p {
      margin-bottom: 10px;
      font-size: 1rem;
      color: #333;
    }

    nav {
      a {
        margin: 0 10px;
        font-size: 0.9rem;
        color: #007BFF;
        text-decoration: none;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}

.credits-display {
  font-size: 1rem;
  font-weight: bold;
  margin-top: 0.5rem;
  text-align: center;
  color: #007BFF !important;
}

.credit-info {
  font-size: 1cap; // Smaller font size
  color: #343333;      // Subtle color to distinguish
  margin-left: 10px; // Add some spacing from the title
}