//@import url(https://fonts.googleapis.com/css?family=Roboto:300);
@import "../../css/constants";

.form-and-chat {
  flex-direction: column;
  align-items: center;
  display: flex;
  height: 100%;
  flex-grow: 1;
}

.login-page-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: #1e2246;
  padding-top: 60px;
}

.loading-popup{
  z-index: 100;
  font-family: $primary-font-family;

  .loading-content button {
    background-color: map-get($colors, background-blue);
    color: white; 
    padding: 10px 20px; /* Padding for a balanced button size */
    border: none; /* Remove any default border */
    border-radius: 5px; /* Rounded corners for a modern look */
    cursor: pointer; /* Pointer cursor to indicate it's clickable */
    font-size: 16px; /* Ensure text is readable */
    margin-top: 10px; /* Space between the button and the text above */
    transition: background-color 0.3s ease; /* Smooth transition on hover */
  }
}

.login-page {
  width: 380px;
  padding: 8% 0 0;
  margin: auto;
  flex-grow: 1;
  min-height: 80vh;
 
  

  .form .message {
    text-align: center;
  }

  /* Styles for "Forget my password" link */
  .forget-password-link {
    color: blue; /* Sets the link color to blue */
    display: block; /* Makes it a block-level element, aiding in center alignment */
    margin-top: 1rem; /* Optionally, adds some space above the link */
  }

  .terms-link {
    display: inline-block;
    background-color: white;
    color: blue;
    text-decoration: none;
    padding: 0.5em 1em;
    margin: 2vh;
    border-radius: 10px;
    cursor: pointer;
    font-weight: bold;
  }
  .form {
    position: relative;
    z-index: 1;
    background: transparent;
    // background: #ffffff;
    max-width: 800px;
    width: 80%;
    margin: 0 auto 100px;
    padding: 40px;
    text-align: left;
    // box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2), 0 5px 5px 0 rgba(0, 0, 0, 0.24);
    font-family: "Arial";
    font-size: 16px;

    .login-form{
      background: transparent;
    }

   p{
      color: map-get($colors, textbox-white);
      margin-bottom: 30px;
      margin-right: "40px";
      font-size: "16px";

   }
   
    input {
      font-family: "Arial";
      outline: 0;
      background:  rgba(255, 255, 255, 0.1);
      border-radius: 10px;
      width: 100%;
      border: 0;
      margin: 0 0 15px;
      padding: 15px;
      box-sizing: border-box;
      font-size: 14px;
      color: #ccc;
    }
    button {
      font-family: $primary-font-family;
      border: none;
      outline: 0;
      background: rgba(255, 255, 255, 0.6);
      width: 100%;
      border-radius: 50px;
      margin-bottom: 20px;
      padding: 15px;
      color:  map-get($colors, button-text-grey);
      font-size: 18px;
      font-weight: 600;
      -webkit-transition: all 0.3 ease;
      transition: all 0.3 ease;
      cursor: pointer;
     

    }

    .sign-up-button{

      background: #eff2f5;
      box-shadow: 0 0 10px rgba(255, 255, 255, 0.8);
    }
    button:hover,
    button:active,
    button:focus {
      background: #ffffff;
    }

    button:disabled {
      background-color: #666; /* Darker grey */
      color: #ccc; /* Light grey text */
      cursor: not-allowed;
      border: 1px solid #444; /* Darker border */
    }
    .message {
      margin: 15px 0 0;
      color: #0669bf;
      font-size: 16px;
    }
    .message a {
      color: #60b3fc;
      text-decoration: none;
      font-weight: bold;
    }
    .register-form {
      display: none;
    }
  }
}

.modal {
  // position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;

  .modal-content {
    background: white;
    padding: 20px;
    border-radius: 5px;
    //max-width: 100vh;
    width: 100%;
  }

  .modal-actions {
    text-align: right;
  }


  .button-container {
    // margin-right: 20vh;
    // margin-bottom: -3vh;
    margin-top: 2vh;
    display: flex;
    flex-direction: row;
    justify-content: center; /* Center the buttons in the row */
    gap: 6vw; /* Increase space between buttons */


    .agree-button {
      background-color: #79ccd5;
      border-radius: 5px;
      border: none;
      width: 8vw;
      height: 5vh;
      font-family: "Arial";
      margin-right: 5vw;
    }
  
    .disagree-button {
      background-color: #fb7684;
      border-radius: 5px;
      border: none;
      width: 8vw;
      height: 5vh;
      font-family: "Arial";
      margin-right: 4vw;
    }
  
  }

  .termsCondition {
    overflow-y: scroll; /* Enables vertical scrolling */
    padding: 40px;
    font-family: "Arial";
    text-align: left;
    padding-top: 5vh;
    padding-left: 10vh;
    padding-right: 10vh;
    padding-bottom: 0vh;
  }
}

.privacy-policy,
.termsCondition {
  font-family: "Arial";
  display: flex;
  width: 80%;
  margin: auto;
  max-width: 1200px;
  flex-direction: column;
  overflow-y: hidden;
  min-height: 100vh;
  // max-height: 100vh;

  .headline {
    margin-top: 10vh;
    font-size: 40px;
    color: #162133;
  }
  .subheadline {
    font-size: 25px;
    color: #3d5a8a;
  }

  .subheadline2 {
    font-size: 20pv;
    font-size: 20px;
    color: rgb(65, 97, 147);
  }
  .application-list {
    color: #040404;
    // text-align: left;
  }
  .paragraph {
    font-size: 20px;
  }

  ul {
    padding-left: 5vh;
  }

  li {
    font-size: 20px;
  }
}

.privacy-footer {
  margin-top: 10vh;
}

.checkbox-container {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  margin-right: 300px;
  justify-content: flex-start;

  .checkbox-label {
    margin-left: 10px;
    margin-right: -240px;
    display: inline-block;
    color: map-get($colors, textbox-white);
    font-family: "Arial";
    width: 200px;
    white-space: nowrap;
  }

  .checkbox-field {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;

    /* Define the size and make it square */
    width: 20px;
    height: 20px;

    border: 1px solid #5c3030;
    border-radius: 1px;

    &:checked {
      background-color: #0669bf;
    }

    margin-right: 10px;
  }
}

/* error message for login/signup */
.errorMessage {
  color: map-get($colors, textbox-white);
  margin-bottom: 5px;
  margin-right: 20px;
  margin-top: 20px;
  font-family: "Arial";
  font-size: 16px;
}

/* TestRegistration.css - example CSS */
.page-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh; /* Use full viewport height */
  justify-content: center; /* Center the content vertically */
  background-color: #1e2246;
}

.signUpText {
  margin-top: 20vh;
  color: #ffffff;
  font-size: 5vh;
}

.testRegistrationForm {
  /* Styles for the form */
  font-size: 30px;
  // background-color: #1e2246;
  min-height: 100vh;
  justify-content: center;
  align-items: center;

  .registration-container {
    text-align: center;

    .emailLabel,
    .emailInput {
      margin-top: 20vh;
      margin-right: 1vh;
      border-radius: 8px;
      padding-top: 1vh;
      padding-bottom: 1vh;
      padding-left: 2vh;
      padding-right: 2vh;
    }
  }
  .submitButton {
    background-color: #007bff;
    border-radius: 8px;
    border: none;
    padding-top: 1vh;
    padding-bottom: 1vh;
    padding-left: 2vh;
    padding-right: 2vh;
    color: white;
    // margin-top: 5vh;
  }
}

.apple-sign-in-button {
  width: 100%;
  padding: 15px;
  background: #000;
  color: white;
  border: none;
  border-radius: 4px;
  margin-top: 10px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s ease;

  &:hover {
    background: #333;
  }
}

@media only screen and (max-width: 1024px) {

  .download-popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
   
  }
  
  .popup-content {
    background: #ffffff;
    border-radius: 10px;
    padding: 20px;
    text-align: center;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
    max-width: 400px;
    width: 80%;
  
  }
  
  .popup-content p {
    font-size: 18px;
    color: #333333;
    margin-bottom: 20px;
    font-family: $primary-font-family;
    text-align: center;
  }
  
  .download-button,
  .continue-button {
    background-color: #007aff; /* iOS blue color */
    color: #ffffff;
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    font-size: 14px;
    cursor: pointer;
    margin: 5px;
    transition: background-color 0.3s ease;
    font-family: $primary-font-family;
  }
  
  .download-button:hover {
    background-color: #005bb5; /* Darker blue on hover */
  }
  
  .close-button {
    background-color: #cccccc;
    color: #333333;
  }
  
  .close-button:hover {
    background-color: #999999;
  }
  }