@import "../../css/constants";

/*---------- Navbar Styling ----------*/
.navbar {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
  background-color: #ffffff; /* White background for a clean look */
  // box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  font-family: $primary-font-family;
  // display: flex;
  justify-content: flex-start;

  /* Navbar Links */
  .nav-link {
    font-size: 20px;
    color: map-get($colors, dark-blue); 
    font-family: $primary-font-family;
    font-weight: 400;
    padding: 20px; 
    transition: color 0.3s ease-in-out, background-color 0.3s ease-in-out;
    text-decoration: none;
    display: block;

  }

  /* Hover Effect for Links */
  .nav-link:hover {
 
    text-decoration: underline; 
    background-color: transparent;
  
  }


  /* Logout Button */
  .nav-item.logout-button {
    position: absolute;
    top: 0;
    right: 0;
    margin-right: 1200px;

  }

  

  .nav-item.logout-button .nav-link {

    border: none; 
    outline: none; 
    background-color: transparent;
  }

  /* Navbar Items */
  .nav-item {
    float: left;
    padding: 0;
  }

  ul {
    display: flex;
    justify-content: flex-end; /* Align menu items to the right */
    list-style: none;
    margin: 0;
    padding: 0;
  }

  li {
    margin: 0;
    padding: 0;
  }
}

/*---------- Dropdown Arrow ----------*/
#navbarSupportedContent a:not(:only-child):after {
  content: "\f105"; /* Font Awesome arrow */
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 14px;
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  transition: transform 0.3s ease;
}

#navbarSupportedContent .active > a:not(:only-child):after {
  transform: translateY(-50%) rotate(90deg); /* Rotate arrow when active */
}

/*---------- Responsive Design ----------*/
@media (max-width: 991px) {
  .nav-item.logout-button {
    position: static !important;
    top: 0;
    right: 0;
    margin-right: 1200px;

  }
  .navbar {
    padding: 10px 20px;
  }

  .nav-item {
    float: none;
  }

  .nav-link {
    padding: 12px 20px;
  }

  ul {
    flex-direction: column; /* Stack menu items vertically */
    align-items: flex-start;
  }
}

@media (min-width: 992px) {
  .navbar-expand-custom {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }

  .navbar-expand-custom .navbar-nav {
    flex-direction: row;
  }

  .navbar-expand-custom .navbar-toggler {
    display: none; /* Hide toggler on large screens */
  }

  .navbar-expand-custom .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
}

.burger-menu-toggle {
  display: none; /* Default to hidden */
}



@media (max-width: 991px) {
  .burger-menu-toggle {
    display: block;
  }


  .menu {
    display: none;
    flex-direction: column;
    align-items: flex-start;
    background-color: #ffffff;
    width: 100%;
    position: absolute;
    top: 60px;
    left: 0;

    &.open {
      display: flex;
    }

    ul {
      width: 100%;
      flex-direction: column;

      .nav-link {
        padding: 15px;
        width: 100%;
        text-align: left;
      }
    }
  }
}

//phone mode

