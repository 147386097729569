@import "../../css/constants";

.home-page {
  position: relative;
  text-align: center;
  line-height: 1.45;
  background: map-get($colors, dark-blue);
  background-size: cover;
  color: rgb(255, 255, 255);
  width: 100%;
  overflow-x: hidden;
}