@import "../../css/constants";

.profile-page {
  display: flex;
  flex-wrap: wrap; /* Allow responsive wrapping */
  // background-color: #0e1636;
  background: #ffffff;
  color: #2b2b2b; 
  padding: 20px;
  font-family: $primary-font-family;
}

.left-column {
  width: 20%; /* Narrower width for the left column */
  padding: 20px;
  text-align: center; /* Center-align content */

}

.third-column {
  width: 25%; /* Center column for main content */
  padding: 20px;
  text-align: left;
}

.right-column {
  width: 45%; /* Right column for additional features */
  padding: 20px;
  text-align: left;
}

.user-avatar img {
  border-radius: 50%;
  width: 120px; /* Adjusted size */
  height: 120px;
  border: 3px solid #ffffff; /* Slimmer white border */
}
.user-info {
  // margin-top: 20px;
  text-align: center; /* Center-align text */
  max-width: 300px; /* Restrict the width */
  margin-left: auto; /* Center horizontally */
  margin-right: auto; /* Center horizontally */
  //background-color: #1e2246; /* Optional: Add background color for better visibility */
  // padding: 10px; /* Add some padding */
  border-radius: 8px; /* Rounded corners */
}

.user-info p {
  font-size: 16px; /* Adjust font size */
  margin: 5px 0;
  color: #323232;
  word-wrap: break-word; /* Ensure long words break into the next line */
  line-height: 1.5; /* Adjust for better readability */
  padding: 20px;
}

// .user-info {
//   margin-top: 20px;
//   text-align: center; /* Center-align text */
// }

// .user-info p {
//   font-size: 16px; /* Adjust font size */
//   margin: 5px 0;
//   color: #ffffff;
// }

.button-container {
  margin-top: 20px;
  display: flex;
  flex-direction: column; /* Stack buttons vertically */
  align-items: center; /* Center-align buttons */
}

.profile-btn {
  background-color:  map-get($colors, profile-button-light); /* White background */
  color: #fafbfc; 
  font-family: $primary-font-family;
  padding: 10px 20px;
  margin: 10px 0;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  width: 80%; /* Wider buttons for better layout */
  text-align: center;
  font-size: 16px;
  transition: all 0.3s ease-in-out; /* Smooth transition for hover effect */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
}

.profile-btn:hover {
  background-color: #41acee; /* Ensure white remains on hover */
  color: #ffffff; /* Text color stays the same */
  box-shadow: 0 0 15px rgba(177, 192, 253, 0.8); /* Glowing effect with teal color */
  transform: scale(1.05); /* Slightly enlarge the button */
}

.profile-btn:focus {
  outline: none; /* Remove default outline */
  box-shadow: 0 0 20px rgb(119, 192, 247); /* Stronger glow when focused */
}
.mood-profile-container {
  // background-color:   map-get($colors, background-blue);
  //border: 3px solid #6fdbca; /* Teal border to define the tab */
  border-radius: 15px; /* Rounded corners */
  padding: 20px; /* Spacing inside the tab */
  margin: 20px 0; /* Spacing around the tab */
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
}



.profile-btn-mood {
  background-color: map-get($colors, profile-button-blue); /* White background */
  color:  white; /* Dark navy blue text */
  font-family: $primary-font-family;
  font-weight: bold;
  padding: 10px 20px;
  margin: 10px 0;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  width: 80%; /* Wider buttons for better layout */
  text-align: center;
  font-size: 16px;
  transition: all 0.3s ease-in-out; /* Smooth transition for hover effect */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
}

.profile-btn-mood:hover {
  background-color:  map-get($colors, rgb(229, 232, 243)); /* Ensure white remains on hover */
  color:  map-get($colors, dark-blue); /* Text color stays the same */
  // box-shadow: 0 0 15px  map-get($colors, rgb(225, 227, 234)); /* Glowing effect with teal color */
  transform: scale(1.05); /* Slightly enlarge the button */
}

.profile-btn-mood:focus {
  outline: none; /* Remove default outline */
  box-shadow: 0 0 20px rgb(229, 233, 247); /* Stronger glow when focused */
}

.profile-btn-chat {
  background-color: #4335A7; /* White background */
  color:  white; /* Dark navy blue text */
  font-family: $primary-font-family;
  font-weight: bold;
  padding: 10px 20px;
  margin: 10px 0;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  width: 80%; /* Wider buttons for better layout */
  text-align: center;
  font-size: 16px;
  transition: all 0.3s ease-in-out; /* Smooth transition for hover effect */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
}

.profile-btn-chat:hover {
  background-color:  map-get($colors, rgb(229, 232, 243)); /* Ensure white remains on hover */
  color:  map-get($colors, dark-blue); /* Text color stays the same */
  // box-shadow: 0 0 15px  map-get($colors, rgb(225, 227, 234)); /* Glowing effect with teal color */
  transform: scale(1.05); /* Slightly enlarge the button */
}

.profile-btn-chat:focus {
  outline: none; /* Remove default outline */
  box-shadow: 0 0 20px rgb(229, 233, 247); /* Stronger glow when focused */
}

.voice-container {
  background-color: #ffffff; /* Light blue background for the tab area */
  //border: 3px solid #0e1636; /* Teal border to define the tab */
  border-radius: 15px; /* Rounded corners */
  padding: 20px; /* Spacing inside the tab */
  margin: 20px 0; /* Spacing around the tab */
  box-shadow: 0 4px 10px rgba(36, 36, 36, 0.1); /* Subtle shadow for depth */
}

.profile-btn-voice {
  background-color:   map-get($colors, profile-button-light); /* White background */
  color: #f4f5f6; /* Dark navy blue text */
  font-family: $primary-font-family;
  // font-weight: bold;
  padding: 10px 20px;
  margin: 10px 0;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  width: 80%; /* Wider buttons for better layout */
  text-align: center;
  font-size: 16px;
  transition: all 0.3s ease-in-out; /* Smooth transition for hover effect */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
}

.profile-btn-voice:hover {
  background-color: map-get($colors, profile-button-blue); /* Ensure white remains on hover */
  color: #f1f2f4; /* Text color stays the same */
  box-shadow: 0 0 15px rgba(177, 192, 253, 0.8); /* Glowing effect with teal color */
  transform: scale(1.05); /* Slightly enlarge the button */
}

.profile-btn-voice:focus {
  outline: none; /* Remove default outline */
  box-shadow: 0 0 20px rgb(119, 192, 247); /* Stronger glow when focused */
}




.delete-account-btn {
  background-color:  map-get($colors, profile-button-light); /* Red for delete button */
  color: #ffffff;
  font-family: $primary-font-family;
  padding: 10px 20px;
  margin: 10px 0;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  width: 80%;
  text-align: center;
  font-size: 16px;
}

.delete-account-btn:disabled {
  background-color: gray;
  cursor: not-allowed;
}

.graph-container {
  width: 100%;
  height: 250px; /* Adjust graph height */
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 10px;
  margin-top: 20px;
}

.graph-container-text p {
  text-align: left;
  font-size: 20px; /* Slightly smaller title */
  // font-weight: bold;
  color:  #323232 !important;
  margin-bottom: 10px;

}

.paragraph-container-text {
  margin-top: 10px;

}

.paragraph-container-text p {
  font-size: 16px; /* Adjust font size for readability */
  line-height: 1.6;
  color: #323232;
  margin-bottom: 20px;
}

.paragraph-container-text ul {
  list-style-type: disc; /* Keep bullet points */
  margin-left: 20px;
  padding-left: 0;

}

.paragraph-container-text li {
  font-size: 16px;
  line-height: 1.6;
  
  margin-bottom: 20px;
  color: #323232;

}

.mood-profile-image {
  width: 100%; // Adjust the width to fit the container
  max-width: 400px; // Limit the maximum width
  margin-top: 10px; // Add spacing above the image
  border-radius: 8px; // Optional: add rounded corners
}

.chat-now-container {
  display: flex;          /* Enables flexbox layout */
  gap: 50px;              /* Adds space between the buttons */
  justify-content: flex-start; /* Aligns buttons to the left */
  align-items: center;  


}
 
/* Add responsiveness */
// @media (max-width: 768px) {
//   .left-column, .middle-column, .right-column {
//     width: 100%; /* Full-width columns for small screens */
//     text-align: center; /* Center-align for better layout */
//   }

//   .button-container {
//     align-items: center; /* Center buttons on small screens */
//   }

//   .profile-btn, .delete-account-btn {
//     width: 90%; /* Adjust button width */
//   }
// }
@media only screen and (max-width: 1024px) {

  .third-column {
    width: 90%; /* Center column for main content */
    padding: 20px;
    text-align: left;
  }

  .left-column {
    width: 90%; /* Narrower width for the left column */
    padding: 20px;
    text-align: center; /* Center-align content */
  
  }

  .right-column {
    width: 90%; /* Right column for additional features */
    padding: 20px;
    text-align: left;
  }

}
