@import "../../css/constants";
/*---------- front page footer ----------*/

// .front-page-footer {
//   flex-shrink: 0;
// }
.front-footer {
  position: relative;
  //height: 15vh;
  text-align: center;
  // background-color: white;
  flex-direction: column;
  font-family: $primary-font-family;
  font-size: 18px;
  padding-top: 5vh;
  background-color: #1e2246;
  line-height: 1.6;

  .footer-content {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 1vh;

    .footer-link {
      margin-bottom: 0.5vh;
      color: #ffffff;
      text-decoration: none;
    }
  }

  .footer-column {
    display: flex;
    flex-direction: column;
    margin-right: 10vw;
  }

  .footer-column a {
    margin-bottom: 0.5vh;
    color: rgb(255, 255, 255);
  }

  .footer-bottom {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 4vh;
    height: 5vh;
    //margin-bottom: 3vh;
    // padding-bottom: 1px;

    p {
      color: #aaaaad;
    }
  }

  .footer-logo {
    display: flex;
    align-items: center;
  }

  .footer-logo img {
    //height: 10vh; /* Adjust the height as needed */
    height: 80px;
    margin-right: 400px;
    border-radius: 8px;
  }
}


@media only screen and (max-width: 1024px) {


  .front-footer {
    position: relative;

    text-align: center;
    flex-direction: column;
    font-family: $primary-font-family;
    font-size: 16px;
    padding-top: 5vh;
    background-color: #1e2246;
    line-height: 1.6;
  
    .footer-content {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 1vh;
  
      .footer-link {
        margin-bottom: 0.5vh;
        color: #ffffff;
        text-decoration: none;
      }
    }
  
    .footer-column {
      display: flex;
      flex-direction: column;
      margin-right: 10vw;
    }
  
    .footer-column a {
      margin-bottom: 0.5vh;
      color: rgb(255, 255, 255);
    }
  
    .footer-bottom {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      margin-top: 4vh;
      height: 5vh;
      //margin-bottom: 3vh;
      // padding-bottom: 1px;
  
      p {
        color: #aaaaad;
      }
    }
  
    .footer-logo {
      display: flex;
      align-items: center;
    }
  
    .footer-logo img {
      display: none;
      //height: 10vh; /* Adjust the height as needed */
      // height: 80px;
      // margin-right: 400px;
      // border-radius: 8px;
    }
  }
}



