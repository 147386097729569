@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700;800;900&display=swap");
@import "css/constants";

#buttonid::-moz-focus-inner,
.buttonclass::-moz-focus-inner,
button::-moz-focus-inner {
  padding: 0 !important;
  border: 0 none !important;
}

.signup-header {
  width: 100%;
  max-width: 100%;
  box-sizing: border-box;
  z-index:100;  
  //position:fixed;


}

.header-intro {
  position: fixed;
  top: 0;
  width: 100%;
  height: 60px; /* Example fixed height */
  z-index: 1000; 
}

/*---------- the get it start button ----------*/
.chatButton-default {
  position: absolute;
  bottom: 10%;
  display: "flex";
}

.chatButton-login {
  position: relative;
  right: 7%;
  bottom: 25%;
  display: flex;
}

.container .btn-holder {
  position: relative;
  text-align: center;
  padding-right: 0;
  padding-top: 0;
  vertical-align: middle;
  display: inline-block;
  margin-top: 3em;
  max-width: 5vh;
  min-width: 1vh;
}

/*---------- font page header settings ----------*/
.main-container {


  display: flex;
  justify-content: center;
  align-items: center;
  height: 50vh;
  flex-wrap: wrap;
  text-align: center;
  padding: 100px 20px;

  color: white;

  .inner-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 2em;
    position: relative;
  }

  .face-photo {
    position: absolute;
    height: auto;
    margin-left: 10vh;
    max-width: 60vh;
    min-width: 30vh;
    margin-top: calc(-5vh);
    width: 35%;

  }

  .title-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-left: 80vh;
    margin-top: -20vh;
    
  }

  #maintitle {
    font-family: "Phenomena";
    font-size: 200px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 8px;
    margin-top: 0vh;
 
   // margin-top: -15vh;
  }

  #subtitle {
    font-family: "Bradley Hand";
    text-align: left;
    font-size: 35px;
    float: center;
    width: 20em;
    color: #f7ffc7;
    z-index: 100;
    margin-top: -230px;

 
   
    
  }

  .chatButton-container-main {
    position: relative;
    height: auto;
    bottom: -30vh;
    max-width: 10%;
    min-width: 5%;
    width: auto;
  }
}




.pulse-button {
  position: relative;
  width: 20%;
  max-height: 10vw;
  min-height: 3vw;
  margin-top: -12em;
  display: block;
  width: 20em;
  font-size: 100%;
  font-weight: bold;
  font-style: normal;
  font-family: "Verdana";
  text-transform: uppercase;
  text-align: center;
  letter-spacing: 2px;
  color: #096278;
  border: none;
  border-radius: 10px;
  background: #ffffff;
  cursor: pointer;
  box-shadow: 0 5px 15px rgba(255, 255, 255, 0.596);
  /* animation: pulse 2s infinite; */
}

.pulse-button:hover {
  transform: scale(1.05);
  animation: pulse 2s infinite;
  -webkit-animation: pulse 2s infinite;
}

p {
  color: #333333;
  text-align: justify;
}

/*---------- frontpage intro blocks ----------*/



.blocks-container {

  display: flex;
  flex-direction: column;
  // gap: 40px;
  // padding: 40px 20px;
  background-color: #ffffff;

  img {
  width: 60%;
  margin: 0 auto;
  height: 250px;
  border-radius: 10px;
  align-items: center;
}
 
  .block {

    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    background: white;
    // box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 20px;
    // border-radius: 10px;
    flex-direction: column;
  
  }

 
  .block-header {
    font-size: 40px; /* Adjust size for emphasis */
    font-weight: 600; /* Make it visually prominent */
    color: #096278; /* Use your theme's color */
    text-align: center; /* Center-align the header */
    margin-bottom: 20px; /* Add space below the header */
    font-family:  $primary-font-family; /* Use a clean and professional font */
    line-height: 1.4; /* Improve readability for multi-line text */
    text-transform: capitalize; /* Optional: capitalize each word */
    letter-spacing: 1px; /* Slight spacing for better readability */
  }

  .block-header-feature {
    font-size: 40px; /* Adjust size for emphasis */
    font-weight: 400; /* Make it visually prominent */
    color: #096278; /* Use your theme's color */
    text-align: left; /* Center-align the header */
    margin-bottom: 20px; /* Add space below the header */
    font-family:  $primary-font-family; /* Use a clean and professional font */
    line-height: 1.4; /* Improve readability for multi-line text */
    text-transform: capitalize; /* Optional: capitalize each word */
    letter-spacing: 1px; /* Slight spacing for better readability */
  }

  .block-header-2 {
    font-size: 30; /* Header size */
    // font-weight: 200; /* Make it stand out */
    font-weight: 400;
    color: #096278; /* Theme color */
    text-align: left; /* Align text to the left */
    margin-bottom: 10px; /* Spacing below the header */
    font-family: $primary-font-family; /* Clean font */
    line-height: 1.4; /* Adjust line spacing */
    text-transform: capitalize; /* Capitalize each word */
    letter-spacing: 0.5px; /* Improve readability */
  }

  .block-header-3 {
    font-size: 30px !important; /* Header size */
    // font-weight: 200; /* Make it stand out */
    font-weight: 400;
    color: #096278; /* Theme color */
    text-align: left; /* Align text to the left */
    margin-bottom: 10px; /* Spacing below the header */
    font-family: $primary-font-family; /* Clean font */
    line-height: 1.4; /* Adjust line spacing */
    text-transform: capitalize; /* Capitalize each word */
    letter-spacing: 0.5px; /* Improve readability */
  }

  .block-header-4 {
    font-size: 22px !important; /* Header size */
    // font-weight: 200; /* Make it stand out */
    width: 80% ;
    font-weight: 400;
    color: #096278; /* Theme color */
    text-align: center; /* Align text to the left */
    margin-bottom: 10px; /* Spacing below the header */
    font-family: $primary-font-family; /* Clean font */
    line-height: 1.4; /* Adjust line spacing */

    letter-spacing: 0.5px; /* Improve readability */
  }



  .highlight {
    font-size: 1.2em;
    color: map-get($colors, title-green); 
    // font-weight: bold;
    font-weight: 500;
  }

  .text-area-feature {
      
    width: 100%;
    max-width: 1200px;
    padding: 20px;
    font-size: 20px;
    color: #333;
    line-height: 1.6;
    text-align: left;
    font-family: $primary-font-family;
    text-align: left;
    font-weight: 300;
    // letter-spacing: 0.5px; 
  }

  .text-area {
      
    width: 40%;
    max-width: 60%;
    padding: 20px;
    font-size: 20px;
    color: #333;
    line-height: 1.6;
    text-align: left;
    font-weight: 300;
    // margin-right: 50px; 
    margin-left: 10px;
    font-family: $primary-font-family;
    letter-spacing: 0.5px; 


  }


  .text-area-dave {
      
    width: 40%;
    max-width: 100%;
    // padding: 20px;
    font-size: 20px;
    color: #333;
    line-height: 1.6;
    text-align: center;
    // margin-right: 150px; 
    // margin-left: 100px;
    font-family: $primary-font-family;
    letter-spacing: 0.5px; 
    font-weight: 300;


  }

  .block-feature {
    background-color: #f8f6f4;
    padding-left: 20vh;
    padding-right: 20vh;

  }

  .block-profile {
    background-color: #ffffff;
    flex-direction: row;
    padding-left: 20vh;
    padding-right: 20vh;

    img {
      // width: 30%;
      // height: auto;
      width: 600px;  // Smaller width for smaller screens
      height: 350px;
      border-radius: 10px;

     
    }
  }


  .block-person {
    background-color: #f8f6f4;
    flex-direction: row-reverse;
    padding-left: 20vh;
    padding-right: 20vh;

    img {
      // width: 30%;
      // height: auto;
      width: 600px;  // Smaller width for smaller screens
      height: 450px;
      border-radius: 10px;

     
    }
  }

  .block-cognitive {
    background-color: #ffffff;
    flex-direction: row;
    padding-left: 20vh;
    padding-right: 20vh;

    img {
      // width: 30%;
      // height: auto;
      width: 250px;  // Smaller width for smaller screens
      height: 320px;
      border-radius: 50%;
      // margin-left: 200px;
    }
   
  }

  .block-strategy {
    background-color: #ffffff;
    flex-direction: row-reverse;
    padding-left: 20vh;
    padding-right: 20vh;
 


  
  }
}

.text-area-2 {
      
  width: 100%;
  max-width: 1200px;
  padding: 20px;
  font-size: 22px;
  color: #333;
  line-height: 1.6;
  text-align: left;
  margin-right: 50px; 
  margin-left: 50px;
  font-family: $primary-font-family;
  text-align: center;
  letter-spacing: 0.5px; 



}

/*---------- Animation wave ----------*/

@keyframes waves {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  50% {
    opacity: 1;
  }
  100% {
    transform: scale(2);
    opacity: 0;
  }
}

.waves {
  position: relative;
  width: 100%;
  height: 3vh;
  margin-bottom: 0 px;
  min-height: 100px;
  margin-top: -45em;

  .parallax > use {
    animation: move-forever 25s cubic-bezier(0.55, 0.5, 0.45, 0.5) infinite;
  }
  .parallax > use:nth-child(1) {
    animation-delay: -2s;
    animation-duration: 7s;
  }
  .parallax > use:nth-child(2) {
    animation-delay: -3s;
    animation-duration: 10s;
  }
  .parallax > use:nth-child(3) {
    animation-delay: -4s;
    animation-duration: 13s;
  }
  .parallax > use:nth-child(4) {
    animation-delay: -5s;
    animation-duration: 20s;
  }
}

@keyframes move-forever {
  0% {
    transform: translate3d(-90px, 0, 0);
  }
  100% {
    transform: translate3d(85px, 0, 0);
  }
}

/* Standard keyframes for non-WebKit browsers */
@keyframes pulse {
  0% {
    transform: scale(0.9);
    box-shadow: 0 0 0 0 rgba(245, 244, 244, 0.963);
  }
  70% {
    transform: scale(1);
    box-shadow: 0 5px 15px rgba(254, 254, 254, 0.95);
  }
  100% {
    transform: scale(0.9);
    box-shadow: 0 5px 15px rgba(255, 255, 255, 0.933);
  }
}

/*pay attention to whether this is css style, not scss*/
@-webkit-keyframes pulse {
  0% {
    transform: scale(0.9);
    box-shadow: 0 0 0 0 rgba(245, 244, 244, 0.963);
  }
  70% {
    transform: scale(1);
    box-shadow: 0 5px 15px rgba(254, 254, 254, 0.95);
  }
  100% {
    transform: scale(0.9);
    box-shadow: 0 5px 15px rgba(255, 255, 255, 0.933);
  }
}


/*----------plan page------------*/
.buy-credit {
  text-align: center;

  font-size: 3vh;
  background-color: #1e2246;
  min-height: 100vh;
  font-family: "Arial";

  .buy-credit-content {
    // margin-top: 10vh;

    .tab-buttons {
      margin-bottom: 20px;
      margin-top: 10vh;
    }

    .tab-buttons button {
      padding: 5vh 10vh;
      margin-right: 10px;
      font-size: 16px;
      background-color: #f0f0f0;
      border: none;
      border-radius: 4px;
      cursor: pointer;
      font-size: 20px;
    }

    .tab-buttons button.active {
      background-color: #007bff;
      color: #fff;
    }

    .buy-button {
      padding: 2vh 4vh;
      font-size: 20px;
      background-color: #007bff;
      color: #fff;
      border: none;
      border-radius: 4px;
      cursor: pointer;
      margin-top: 10vh;
    }
  }
}

.assign-credit {
  text-align: center;

  font-size: 3vh;
  background-color: #1e2246;
  min-height: 100vh;
  font-family: "Arial";

  .buy-credit-content {
    // margin-top: 10vh;

    .api-containter {
      margin-top: 10vh;
    }

    .tab-buttons {
      margin-bottom: 20px;
      margin-top: 20vh;
    }

    .tab-buttons button {
      padding: 5vh 10vh;
      margin-right: 10px;
      font-size: 16px;
      background-color: #f0f0f0;
      border: none;
      border-radius: 4px;
      cursor: pointer;
      font-size: 20px;
    }

    .tab-buttons button.active {
      background-color: #007bff;
      color: #fff;
    }

    .buy-button {
      padding: 2vh 4vh;
      font-size: 20px;
      background-color: #007bff;
      color: #fff;
      border: none;
      border-radius: 4px;
      cursor: pointer;
      margin-top: 10vh;
    }
  }
}


// @media (max-width: 768px) {
//   img {
//     max-width: 50%; /* Shrink further on smaller screens */
//   }
// }

@media only screen and (max-width: 1024px) {

  .waves {
    height: 10vh;
    min-height: 10vh;
    z-index: 2;
  }
  .content {
    height: 30vh;
  }
  h1 {
    font-size: 24px;
  }

  .pulse-button {
    position: relative;
    width: 200px !important;
    height: 100px !important;
    // min-height: 3vw;
    // margin-top: -12em;
    // display: block;
    width: 10em;
    font-size: 100%;
    font-weight: bold;
    font-style: normal;
    font-family: "Verdana";
    text-transform: uppercase;
    text-align: center;
    letter-spacing: 0.5px !important;
    color: #096278;
    border: none;
    // border-radius: 10px;
    background: #ffffff;
    cursor: pointer;
    box-shadow: 0 5px 15px rgba(255, 255, 255, 0.596);
    top: -35vh;


    /* animation: pulse 2s infinite; */
  }
  
  .pulse-button:hover {
    transform: scale(1.05);
    animation: pulse 2s infinite;
    -webkit-animation: pulse 2s infinite;
  }

  .main-container {
    flex-direction: column;
    min-height: 80vh;
    justify-content: center;
    align-items: center;
    display: flex;

 
    .inner-header {
      display: flex;
      flex-direction: column;
      gap: 1em;
      // margin-top: 5vh;
      align-items: center;
      min-height: 100vh;
      background: #162133;

    }

    #maintitle {
      position: relative !important;
      font-size: 20vw;
      // margin-bottom: 20vh;
      z-index: 2;
      font-family: "Phenomena";
      right: 15%;
      top: 2vh

    
      // top: 65vh;
    }
    #subtitle {
      font-family: "Bradley Hand";
      color: #f7ffc7;
      position: relative;
      font-size: 6vw;
      //left: 50%;
      transform: translateX(-45%);
      z-index: 2;
      // top: 30vh;
      top: 20vh;
      left: 65%;
    }

    .title-container {
      position: relative;
      margin-left: 2vh;
      margin-top: 0;
      align-items: center;
      flex-direction: column;
    }
  }

  .face-photo {
    position: relative !important;
    width: 350px !important;
    right: 20%;
    // margin-left: auto;
    // margin-right: 5vh;
    top: 10vh;

  }

  .chatButton-container-main {
    z-index: 100;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    top: 80vh;
    right: 15%;

  }

  .block-header {
    font-size: 25px !important; /* Adjust size for emphasis */
    font-weight: 600; /* Make it visually prominent */

    text-align: center; /* Center-align the header */
    margin-bottom: 20px; /* Add space below the header */
    font-family:  $primary-font-family; /* Use a clean and professional font */
    line-height: 1.4; /* Improve readability for multi-line text */
    text-transform: capitalize; /* Optional: capitalize each word */
    letter-spacing: 0.5px; /* Slight spacing for better readability */
  }

  .block-header-feature {
    font-size: 40px; /* Adjust size for emphasis */
    font-weight: 400; /* Make it visually prominent */
    color: #096278; /* Use your theme's color */
    text-align: left; /* Center-align the header */
    margin-bottom: 20px; /* Add space below the header */
    font-family:  $primary-font-family; /* Use a clean and professional font */
    line-height: 1.4; /* Improve readability for multi-line text */
    text-transform: capitalize; /* Optional: capitalize each word */
    letter-spacing: 1px; /* Slight spacing for better readability */
  }

  .block-header-3 {
    font-size: 30px !important; /* Header size */
    // font-weight: 200; /* Make it stand out */
    font-weight: 400;
    color: #096278; /* Theme color */
    text-align: left; /* Align text to the left */
    margin-bottom: 10px; /* Spacing below the header */
    font-family: $primary-font-family; /* Clean font */
    line-height: 1.4; /* Adjust line spacing */
    text-transform: capitalize; /* Capitalize each word */
    letter-spacing: 0.5px; /* Improve readability */
  }

  .block-header-4 {
    font-size: 20px !important; /* Header size */
    // font-weight: 200; /* Make it stand out */
    width: 80% ;
    font-weight: 400;
    color: #096278; /* Theme color */
    text-align: center; /* Align text to the left */
    margin-bottom: 10px; /* Spacing below the header */
    font-family: $primary-font-family; /* Clean font */
    line-height: 1.4; /* Adjust line spacing */

    letter-spacing: 0.5px; /* Improve readability */
  }

  

  .text-area-feature {
      

  
    width: 80% !important;
    font-size: 16px !important;
    color: #333;
    line-height: 1.6;
    text-align: left;
    font-family: $primary-font-family;
    font-weight: 300;

    // letter-spacing: 0.5px; 
  }

 

  .text-area-2 {
      
    width: 70%;
    // max-width: 60%;
    // padding: 10px;
    font-size: 16px;
    color: #333;
    line-height: 1.6;
    text-align: center;
    font-weight: 300;
    // margin-right: 50px; 
    // margin-left: 10px;
    font-family: $primary-font-family;
    letter-spacing: 0.5px; 


  }



  .text-area-dave {
      
    width: 80% !important;
    max-width: 100%;
    // padding: 20px;
    font-size: 20px;
    color: #333;
    line-height: 1.6;
    text-align: center;

    font-family: $primary-font-family;
    letter-spacing: 0.5px; 
    font-weight: 300;


  }


  .blocks-container {
    display: flex;
    flex-direction: column;
    background-color: #dad8d6;

 

    .block {
      display: flex;
      align-items: center;
      justify-content: space-around;
      padding: 10px;
      flex-direction: column;

      .text-area {
        max-width: 600px; /* Restrict width for better readability */
        padding: 20px; /* Add inner spacing */
        background-color: #ffffff; /* Optional: Add a background */
        border-radius: 10px; /* Rounded corners */
        box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
        text-align: left;
        color: #333; /* Darker color for better contrast */
        font-size: 16px;
        font-family: 'Arial', sans-serif;
        line-height: 1.6; /* Increase line spacing for readability */
        margin-bottom: 20px; /* Space between text and next content */
      }
  

    }

   
    .block-person {
      background-color: #ffffff;
      flex-direction: column;
      // width: 800px !important;

      img {
        // width: 30%;
        // height: auto;
        max-width: 100% !important;  // Smaller width for smaller screens
        height: auto;
        border-radius: 10px;
        margin-left: 0;
       
      }

      .text-area {
      
        width: 80% !important;

        padding: 30px;
        font-size: 16px;
        color: #333;
        line-height: 1.6;
        text-align: left;
        font-weight: 300;
        // margin-right: 50px; 
        // margin-left: 10px;
        font-family: $primary-font-family;
        letter-spacing: 0.5px; 
        margin-right: 50%;
    
    
      }

  
    }

    .block-profile {
      background-color: #ffffff;
      flex-direction: column;
      // width: 800px !important;

      img {
        // width: 30%;
        // height: auto;
        max-width: 100% !important;  // Smaller width for smaller screens
        height: auto;
        border-radius: 10px;
        margin-left: 0;
       
  
       
      }

      .text-area {
      
        width: 80% !important;

        padding: 30px;
        font-size: 16px;
        color: #333;
        line-height: 1.6;
        text-align: left;
        font-weight: 300;
        // margin-right: 50px; 
        // margin-left: 10px;
        font-family: $primary-font-family;
        letter-spacing: 0.5px; 
        margin-right: 50%;
    
    
      }

  
    }
  
    .block-header-2 {
      font-size: 30; /* Header size */
      // font-weight: 200; /* Make it stand out */
      font-weight: 400;
      color: #096278; /* Theme color */
      text-align: left; /* Align text to the left */
      margin-bottom: 10px; /* Spacing below the header */
      font-family: $primary-font-family; /* Clean font */
      line-height: 1.4; /* Adjust line spacing */
      text-transform: capitalize; /* Capitalize each word */
      letter-spacing: 0.5px; /* Improve readability */
      
    }

    .block-cognitive {
      background-color: #ffffff;


      img {
        width: 230px;
        height: 300px;

        border-radius: 50%;

      }
    }

    .block-strategy {
      background-color: #ffffff;

      img {
        width: 400px;
        height: 80px;

        border-radius: 8px;

      }
    }
  }
}
