@font-face {
  font-family: "Phenomena";
  src: url("../assets/fonts/Phenomena-Black.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Bradley Hand";
  src: url("../assets/fonts/BradleyHandITCTT-Bold.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}


$primary-font-family:  Poppins;
$colors: (
  // dark-blue: rgba(21, 33, 51, 255),
  light-background: #dad8d6,
  warm-background: #f8f6f4,
  aqua: #79ccd5,
  dark-blue: #162133,
  foot-note-blue: #1e2246,
  lighter-grey: #e1d6d6,
  yellow: #e7c30b,
  payment-tab: rgba(0, 97, 242, 0.2),
  title-green: #096278,
  title-green-light: rgba(9, 98, 120, 0.5),
  sub-title-green: #6f8d94,
  textbox-white: rgba(255, 255, 255, 0.8),
  button-text-grey:#5e5e5e,
  textbox-white-light: rgba(255, 255, 255, 0.2),
  background-blue:#1e2246,
  text-grey:#4b4a4a,
  profile-button-blue: #4CC9FE,
  profile-button-light: #007BFF, 
  text-color: #333333,
  

  



);


