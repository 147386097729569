@import "../../css/constants";
.bookclub-container {
  font-family: $primary-font-family;
  padding: 20px;
  background-color: #f6f7f8;
  

  h1 {
    color: #1a1a1b;
    text-align: center;
    margin-bottom: 15px;


  }



  p {
    color: #717274;
    text-align: center;
    margin-bottom: 20px;
  }

  img {
    width: 400px;
    height: 400px;
    border-radius: 10px;
    margin: 20px;


  }

  .articles-info{
    max-width: 1200px; /* Set the maximum width to make it narrower */
    margin: 0 auto; /* Center the content horizontally */
    padding: 20px; /* Add some padding for spacing */
    background-color: #f6f7f8; /* Optional: Background color for better visual separation */
  

  }

  .article {
    background-color: #ffffff;
    border: 1px solid #e1e1e3;
    padding: 20px;
    margin: 10px 0;
    display: flex;
    flex-direction: column;
    align-items: center; /* Center content horizontally */

    .row {
      display: flex;
      flex-direction: column;
      align-items: center; /* Center items vertically */
      justify-content: center; /* Center items horizontally */
      margin-bottom: 15px;
      width: 100%;
    }

    h2 {
      color: #1a1a1b;
      margin-bottom: 10px;
      font-size: 30px;
    }

    p {
      color: #292929;
      margin-bottom: 15px;
      font-size: 14px;
    }

    button {
      background-color: #0079d3;
      color: #fff;
      border: none;
      padding: 8px 12px;
      border-radius: 4px;
      cursor: pointer;
      font-size: 13px;

      &:hover {
        background-color: #005999;
      }
    }

    .comments-section {
      margin-top: 20px;

      h3 {
        color: #1a1a1b;
        font-size: 16px;
        margin-bottom: 15px;
      }

      ul {
        list-style: none;
        padding-left: 0;

        li {
          padding: 10px;
          background-color: #f8f9fa;
          border: 1px solid #e1e1e3;
          margin-bottom: 8px;
          position: relative;

          &:hover {
            background-color: #f1f1f2;
          }

          .comment-header {
            display: flex;
            justify-content: space-between;
            align-items: center;

            strong {
              font-size: 13px;
              color: #0079d3;
            }
          }

          .comment-text {
            margin: 5px 0;
            color: #292929;
            font-size: 14px;
          }

          .comment-actions {
            display: flex;
            gap: 10px;
            font-size: 12px;
            color: #555;
            margin-top: 5px;

            button {
              background: none;
              border: none;
              color: #0079d3;
              cursor: pointer;

              &:hover {
                text-decoration: underline;
              }

              &.delete {
                color: #d63638;

                &:hover {
                  text-decoration: underline;
                }
              }
            }
          }

          .replies {
            margin-top: 10px;
            margin-left: 20px;
            border-left: 2px solid #e1e1e3;
            padding-left: 10px;
          }
        }
      }

      .comment-input {
        margin-top: 15px;


        textarea {
          width: 100%;
          height: 60px;
          padding: 10px;
          border: 1px solid #e1e1e3;
          border-radius: 4px;
          resize: none;
          font-size: 13px;
          font-family: inherit;
          margin-bottom: 10px;
          margin-top: 20px;
        }

        .comment-controls {
          display: flex;
          gap: 10px;

          input {
            flex: 1;
            padding: 10px;
            border: 1px solid #e1e1e3;
            border-radius: 4px;
            font-size: 13px;
          }

          button {
            background-color: #0079d3;
            color: #fff;
            border: none;
            padding: 8px 15px;
            border-radius: 4px;
            cursor: pointer;
            font-size: 13px;

            &:hover {
              background-color: #005999;
            }
          }
        }
      }
    }
  }
}

.reply-input {
  display: flex;
  flex-direction: column;
  gap: 8px; /* Add some space between input and textarea */
  margin-top: 10px;


  .name-input {
    width: 50%; /* Adjust the width as needed */
    padding: 8px;
    border: 1px solid #e1e1e3;
    border-radius: 4px;
    font-size: 13px;
  }

  textarea {
    width: 90%;
    height: 60px;
    padding: 10px;
    border: 1px solid #e1e1e3;
    border-radius: 4px;
    resize: none;
    font-size: 13px;
    font-family: inherit;
  }

  button {
    align-self: flex-start; /* Align the button to the left */
    background-color: #0079d3;
    color: #fff;
    border: none;
    padding: 8px 12px;
    border-radius: 4px;
    cursor: pointer;
    font-size: 13px;

    &:hover {
      background-color: #005999;
    }
  }
}