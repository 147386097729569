@import "../../css/constants";
.support-background {
  // background-image: url('../../assets/bg_night_sky.jpg');
  background-color:  #f8f6f4;;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  min-height: 80vh;
  margin: 0;
  padding: 0;

    /* Add an overlay using the ::before pseudo-element */
  // &::before {
  //   content: "";
  //   position: absolute; /* Position this overlay absolutely relative to .support-background */
  //   top: 0;
  //   left: 0;
  //   width: 100%;
  //   height: 100%;
  //   background-color: rgba(0, 0, 0, 0.4); /* Black overlay with 50% opacity */
  //   z-index: 1; /* Ensure the overlay is on top of the background but behind the content */
  // }
}



.support-container {
  position: relative;
  z-index: 3; /* Ensure the form content is above both the background and overlay */
  // min-height: 50vh;
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  // background-color: rgba(249, 249, 249, 0.8); /* Semi-transparent white background */
  border-radius: 8px;
  // color: #ffffff;
  color:map-get($colors, background-blue);
  font-family: $primary-font-family;

  top: 20px; 
  // left: 50%;
  // transform: translateX(-50%);  /* Center horizontally */
  padding: 20px;




  p{
    color:map-get($colors, background-blue);
    // color: #ffffff;
    margin-top: 50px;
    margin-bottom: 30px;
  }

  label {

    color: map-get($colors,text-grey);
    
  }



}



.form-group {

  margin-bottom: 15px;
}

.form-control {
  width: 100%;
  padding: 10px;
  margin-top: 5px;
  background-color: rgba(255, 255, 255, 0.1); /* Change this to the color you want */
  border-radius: 5px; /* Optional: Rounded corners for the input */
  border: 1px solid #ccc; /* Optional: Light border to define the input box */
  // color: #ffffff; /* Text color inside the input field */
  color: map-get($colors,text-grey);

}

.btn-primary {
  background-color: map-get($colors, profile-button-light);;
  color: #ffffff;
  padding: 10px 20px;
  border: none;
  cursor: pointer;
  border-radius: 5px;

  &:disabled {
    background-color: #cccccc; /* Grey background when disabled */
    color: #666666; /* Grey text color when disabled */
    cursor: not-allowed; /* Change cursor to indicate the button is not clickable */
  }
}

.feedback {
  margin-top: 15px;
  color: green;
}


@media only screen and (max-width: 1024px) {

  .support-container {
    position: relative;
    z-index: 3; /* Ensure the form content is above both the background and overlay */
    // min-height: 50vh;
    max-width: 75%;
    margin: 0 auto;
    padding: 20px;
    // background-color: rgba(249, 249, 249, 0.8); /* Semi-transparent white background */
    border-radius: 8px;
    // color: #ffffff;
    color:map-get($colors, background-blue);
    font-family: $primary-font-family;
  
    top: 20px; 
    // left: 50%;
    // transform: translateX(-50%);  /* Center horizontally */
    padding: 20px;
  
  
  
  
    p{
      color:map-get($colors, background-blue);
      // color: #ffffff;
      margin-top: 50px;
      margin-bottom: 30px;
    }
  
    label {
  
      color: map-get($colors,text-grey);
      
    }
  
  
  
  }
  

}