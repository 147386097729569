@import "../../css/constants";

$primary-color: #2c3e50; // Dark Blue
$secondary-color:map-get($colors, warm-background);  // Light Grey
$accent-color: #badaef; // Vibrant Blue
$hover-accent-color: #badaef; // Darker Blue
$error-color: #e74c3c; // Red for errors
$success-color: #2ecc71; // Green for success
$text-color: #333; // Neutral text color
$button-text-color: #fff; // Button text
$card-background: #ffffff; // White card background
$shadow-color: rgba(0, 0, 0, 0.1); // Light shadow for depth
$border-color: #c1bfbf; // Light grey border

.buy-credit {
  font-size: 16px;
  background-color: $secondary-color;
  color: $text-color;
  min-height: 100vh;
  padding: 100px;
  font-family: $primary-font-family;
 


  .top-info {
    display: flex;
    padding: 10px 20px;
    border-radius: 8px;
    color: #000;
    align-items: center; 
    justify-content: space-between; 


    .current-plan {
      font-size: 18px;
      font-weight: 500;

      p {
        margin: 5px 0;
      }
    }

    .auto-renew-toggle {
      display: flex;
      align-items: center;
      justify-content: center;

      span {
        font-size: 16px;
        margin-right: 10px;
     
      }
    }
  }

  .buy-credit-content {
    margin-top: 20px;
    // padding: 20px;

    .pricing-container {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
      gap: 20px;

    button {
      background-color: #ffffff;
      // color: $button-text-color;
      // padding: 15px;
     
      border-radius: 8px;
      border: 2px solid $border-color;
      transition: all 0.3s ease; 
      padding: 40px;
      // transition: background-color 0.3s ease, transform 0.3s ease;

      &:hover {
        background-color: $hover-accent-color;
        transform: translateY(-3px);
      
      }

      &.active {
        background-color: $accent-color;
        border-color: $hover-accent-color;
        color: #ffffff;
      }

      &.deactivated {
        background-color: $secondary-color;
        color: $text-color;
        cursor: not-allowed;
        opacity: 0.6;
      }

    }
    }

     .month{
      color: #272827;
     }

     .description{
      margin-top: 10px;
      color: #272827;
      text-align: left;
      line-height: 1.6;
      font-size: 16px;

 
   
     }

      h1 {
        font-size: 20px;
        margin-bottom: 5px;
      }

   
    }
  

  .ios-subscription-message {
    margin: 20px 0;
    padding: 15px;
    background-color: $error-color;
    color: $button-text-color;
    border-radius: 8px;
    text-align: center;
  }


}

.buy-credit-button {

  max-width: 400px;
  // height: 50px;
  background-color: map-get($colors, profile-button-light) !important;
  color: $button-text-color;
  border-radius: 20px !important;
  font-size: 18px;
  margin-top: 50px;
  padding: 20px 30px !important; 


  &:hover {
    background-color: darken($success-color, 10%);
  }
}

// @import "../../css/constants";


$button-gradient-start: #333;
$button-gradient-end: #000;
$button-hover-gradient-start: #555;
$button-hover-gradient-end: #222;
$button-text-color: #fff;
$gold-color: rgba(255, 215, 0, 0.9);
$shadow-color: rgba(0, 0, 0, 0.5);


//   .buy-credit{
//     font-size: 25px;
//     // color: map-get($colors, warm-background);
//     min-height: 100vh;
//     // color: #000 !important;



//   // Current Plan and Switch container
//   .top-info {
//     display: flex;
//     justify-content: space-between; // Ensures space between elements
//     align-items: center; // Aligns elements vertically in the center
//     width: 100%; // Ensures the container spans the full width of its parent
//     padding: 0 20px; // Adds padding on both sides
//     background-color: map-get($colors, warm-background);
  
//     .current-plan {
//       font-size: 20px;
//       font-weight: 300;
//       color: linear-gradient(to right, #56CCF2 0%, #2F80ED 51%);
//       margin-top: 100px;
//       margin-left: 300px;
//       position: relative;

//       p{
//         color: white;
//         // margin-left: 10vw;
//         margin-top: 20px;
//         font-size: 20px;
//         font-family: $primary-font-family;
//       }
//     }    
  
//     .auto-renew-toggle {
//       // display: flex;
//       align-items: center; // Aligns the text and switch vertically
//       margin-right:400px;
//       margin-top: 100px;
  
//       span {
//         margin-right: 10px; // Space between the label "Auto Renew" and the switch
//         font-size: 20px;
//         color: #2b2929; // Text color for the "Auto Renew" label
//       }
  
//       .MuiSwitch-root {
//         margin-top: 0; 
//         width: 70px; // Adjust width if needed
//         height: 34px; // Adjust height if needed
       
//       }

//       .MuiSwitch-switchBase {
//         padding: 9px;
//         &.Mui-checked {
//           transform: translateX(20px); // Move thumb to the right when active
//           color: #fff; // Thumb color when checked
//           + .MuiSwitch-track {
//             background-color: map-get($colors, aqua); // Green background when active
//           }
//         }
//       }

//       .MuiSwitch-thumb {
//         box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
//         width: 24px; // Increase thumb size
//         height: 24px; 
//       }

//       .MuiSwitch-track {
//         border-radius: 20px;
//         background-color: #BDBDBD;
//         opacity: 1;
//         box-sizing: border-box;
//         height: 20px; 
//       }
//     }
  
 
//   }
//   }

//   /* Main content container */
//   .buy-credit-content {
//     // display:flex;
//     // flex-direction: column; 
//     height: 80vh;
//     max-width: 1500px;
//     margin: 30px auto;
//     padding: 20px;
//     background-color: map-get($colors, warm-background);
//     border-radius: 10px;
//     font-family: $primary-font-family;
//     position: relative;

//     // padding-top: 60px;
    
    

//     .pricing-container {
//         display: flex;
//         // align-items: baseline; 
//         justify-content: center; 

//         // font-size: 36px; 

//     h1 {
//         font-size: 26px;
//         font-weight: bold;
//         margin: 10px 0;
//         color: #FFFFFF; /* Adjust color if needed */
//       }

//     .pricing {
//         font-size: 20px;
//         font-weight: bold;
//        // color: #9A9FBF; /* Lighter grey color for prices */
//         margin: 5px 0 20px;
//         display: inline;

  
//       }

//     .month {
//         font-size: 20px;
//         color: #e1d6d6; /* Lighter grey color for prices */
//         margin: 5px 0 20px;
//         display: inline;
//         font-weight: bold;
//       }

//       .tokens {
//         font-size: 16px;
//         color: white; /* Lighter grey color for prices */
//         margin: 5px 0 20px;
//         display: block;
//         font-weight: bold;
      
//       }

//       .description {
//         font-size: 16px; /* Adjust this size based on your design */
//         color: #ffffff; /* Color of the text */
//         margin-top: 10px; /* Space between the plan details and description */
//         line-height: 1.5; /* Line height for better readability */
//         max-width: 300px; /* Allow wider text layout for readability */
//         text-align: left; /* Center the text within the button */
//         opacity: 0.8; /* Slight opacity for a softer appearance */
//       }
//   }
// }

// .ios-subscription-message {
//   background-color: #f9e7e9; /* Light red background to grab attention */
//   color: #721c24; /* Dark red text for contrast */
//   border: 1px solid #f9e7e9; /* Border with a slightly darker red */
//   border-radius: 5px; /* Rounded corners */
//   padding: 15px; /* Some padding around the message */
//   margin: 20px 0; /* Margin to separate from other elements */
//   text-align: center; /* Center the text */
//   font-size: 16px; /* Adjust font size for readability */
// }

// .ios-subscription-message p {
//   margin: 0; /* Remove default margin from paragraph */
// }
  
// /
// /* Base styles for all buttons */

// .tab-buttons {
//   // display: flex;
//   justify-content: space-around; // Ensures tabs are evenly spaced
//   width: 90%; 
//   flex-wrap: wrap; 




//   button {
//     flex: 0 0 auto; // Prevents tabs from growing or shrinking
//     width: calc(33.3% - 20px); // Adjusts width accounting for the gap; change the percentage as needed
//     // color: white;
//     padding: 15px 15px !important;
//     color: #000 !important;
 
//     // padding: 5vh 15vh !important;
//     border-radius: 10px;
//     cursor: pointer;
//     transition: 0.3s ease;
//     background-size: 200% auto;

    
//     &:hover {
//       background-position: right center; // Enhance hover effect
//     }

// }
// button.basic {
//   width: 20vw;
 
//   // background-image: linear-gradient(to right, #56CCF2 0%, #2F80ED 51%);
//   // background-image: linear-gradient(145deg, $button-gradient-start, $button-gradient-end);
//   border: 2px solid map-get($colors, aqua) !important; 


// }

// button.basic.active {

//   // background-image: linear-gradient(to right, #2F80ED 0%, #1B4FAD 51%);
//   // background: map-get($colors, payment-tab) !important;
// }

// button.free {
//   width: 20vw;

//   // background-image: linear-gradient(145deg, $button-gradient-start, $button-gradient-end);
//   border: 2px solid map-get($colors, aqua) !important; 


// }

// // button.free.active {

// //   // background-image: linear-gradient(to right, #2F80ED 0%, #1B4FAD 51%);
// //   // background: map-get($colors, payment-tab) !important;
// // }


// /* Styling for Standard plan */
// button.plus {
//   width: 20vw;
//   // background-image: linear-gradient(to right, #6DD5FA, #2980B9);
//   // background-image: linear-gradient(145deg, $button-gradient-start, $button-gradient-end);
  
//   border: 2px solid yellow !important; 
  
// }

// button.plus.active {
//   // background-image: linear-gradient(to right, #2980B9, #176887);
//   // background-image: linear-gradient(to right, #2F80ED 0%, #1B4FAD 51%);
//   // background: map-get($colors, payment-tab) !important;
// }

// /* Styling for Pro plan */
// button.premium {
//   width: 20vw;
//   // background-image: linear-gradient(145deg, $button-gradient-start, $button-gradient-end);
//   border: 2px solid rgb(178, 119, 255) !important; 
//   // background-image: linear-gradient(to right, #8E2DE2, #4A00E0);
// }

// button.premium.active {
//   // background: map-get($colors, payment-tab) !important;
//   // background-image: linear-gradient(to right, #2F80ED 0%, #1B4FAD 51%);
// }


// /* Hover effects for all buttons */
// button:hover {
//   background-position: right center; /* shift the background */
// }

// /* Active button styling */
// button.active {
//   box-shadow: 0 4px 15px 0 rgba(0, 0, 0, 0.2);
// }

//  /* Active button styling */
//  /* Deactivated button styling */
// button.deactivated {
// // background-image: linear-gradient(to right, #333333, #333333); /* Darker background for deactivated */
// color: #aaaaaa; 
// cursor: not-allowed; 
// opacity: 0.6;
// box-shadow: none; 
// }
// }

 
// .buy-credit-button {
//     background-color: #27ae60;
//     // background: linear-gradient(to right,  #2c00ff, #caf0ff, #2c00ff);
//     color: map-get($colors, dark-blue) !important;
//     // border: none;
//     padding: 20px !important;
//     font-size: 24px !important;
//     cursor: pointer;
//     margin-top: 50px;
//     border-radius: 10px;
//     //width: 20%;
//     align-self: center;
//     transition: background-color 0.3s ease;
//     font-weight: bold;
//   }
  

.card-element {
    color: white; // This won't directly apply to CardElement but good for consistency
  }


.payment-form-container {
    background-color: transparent;
    padding: 5vh;
    border-radius: 8px;
    // box-shadow: 0 4px 8px rgba(73, 73, 73, 0.1);
    width: 60vh;
    margin: auto;
    margin-top:10vh;
    // font-family:Arial;
    font-family: $primary-font-family;


    .credit-summary{
      p {
        color: map-get($colors, textbox-white);
        margin-bottom: 10px;
      }
    }

    form {
        display: flex;
        flex-direction: column;
       
    }
    
    input[type="text"], input[type="radio"] {
        margin-bottom: 10px;
        padding: 12px;
        border: 1px solid #ccc;
        border-radius: 10px;
        background-color: map-get($colors, textbox-white-light);
        color: #ffffff;
     
    }
    
    input[type="text"] {
        width: 100%;
    }
    
    .card-details, .billing-address, .exp-cvc-group, .name-group {
        margin-bottom: 20px;
    }
    
    .exp-cvc-group input, .name-group input {
        width: calc(33.333% - 6px);
        display: inline-block;
        margin-right: 10px;
    }
    
    .exp-cvc-group input:last-child, .name-group input:last-child {
        margin-right: 0;
    }
    
    label {
        display: block;
        margin-bottom: 5px;
        font-size: 14px;
        color: #ede7e7;
    }
    
    button {
        background-color: map-get($colors, textbox-white);
        color: map-get($colors, button-text-grey);
        padding: 12px;
        border: none;
        border-radius: 50px;
        cursor: pointer;
        font-size: 16px;
        margin-top: 2vh;
     
    }
    
    button:hover {
        background-color: #ffffff;
    }
    
    /* Hide the default Stripe CardElement styling */
    .StripeElement {
        width: 100%;
        padding: 12px;
        border: 1px solid #ccc;
        border-radius: 4px;
        box-sizing: border-box;
    }
    
    .StripeElement--focus {
        border-color: #0061F2;
    }
    
}

//extra credits

// .extra-credits {

//   margin-left: 10px;
//   display: flex;
//   justify-content: space-around; 
//   flex-direction: column;
//   gap: 16px; // Adjust gap between buttons
//   padding: 10px;
//   background-color: #000; // Background color
//   border-radius: 10px;

//   button {
//     // flex: 1 1 20%;
//     background: linear-gradient(145deg, $button-gradient-start, $button-gradient-end);


//     border-radius: 10px;
//     color: $button-text-color;
//     font-size: 16px;
//     font-weight: bold;
//     position: relative;
//     // overflow: hidden;
//     width: 100%;
//     transition: background-color 0.3s, box-shadow 0.3s;

//     &:hover {
//       background: linear-gradient(145deg, $button-hover-gradient-start, $button-hover-gradient-end);
//       cursor: pointer;
//       box-shadow: 0 4px 8px $shadow-color;
//     }

//     &::before {
//       content: '';
//       position: absolute;
//       top: 0;
//       right: 0;
//       width: 60px;
//       height: 100%;
//       background: $gold-color;
//       clip-path: polygon(100% 0, 0 0, 100% 100%);
//       transition: width 0.3s;
//     }

//     &:hover::before {
//       width: 70px; // Slightly expand the golden clip path on hover
//     }

//     &.popular::before, &.best-value::before {
//       content: attr(class); // Uses the class name as content dynamically
//       font-size: 12px;
//       font-weight: normal;
//       text-transform: uppercase;
//       color: #000;
//       display: flex;
//       align-items: center;
//       justify-content: center;
//     }
//   }
// }
//payment success page

.success-page {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f0f2f5;

  .success-card {
    background: white;
    padding: 2rem;
    border-radius: 10px;
    text-align: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 400px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .success-icon {
      color: #4CAF50;
      font-size: 46px;
      i.checkmark {
        line-height: 50px;
      }
    }

    h2 {
      color: #333;
      font-size: 22px;
      margin-top: 24px;
    }

    p {
      color: #666;
      margin-top: 8px;
      margin-bottom: 20px;
    }

    .start-btn {
      background-color: #6200ee;
      color: white;
      border: none;
      border-radius: 20px;
      padding: 10px 20px;
      font-size: 16px;
      cursor: pointer;
      outline: none;
      &:hover {
        background-color: #3700b3;
      }
    }
  }
}





// @media (max-width: 600px) {
//   .pricing-container {

//     font-size: 36px;

//   }

//   .tab-buttons {
//     flex-direction: column; 
//     align-items: center; 
//     width: 100%;
//     min-height: 80vh;
//     max-width: 300px; // Sets a maximum width for each button
//     padding: 15px 30px; // Adjust padding as needed
//     font-size: 24px; // Font size can be adjusted based on design requirements
//     margin: 5px; // Adds space around the buttons
//    // display: flex;
//   //  justify-content: space-between;
//   //  padding: 0 20px; // Adjust padding as needed for tablet screens

//     button {
//       width: 90%; // Adjust the width to fit the container better
//       margin: 10px 0; // Provides space between buttons vertically
//       padding: 12px; // Adjust padding as needed
//         // flex-grow: 1; // Allow buttons to fill the space dynamically
//         // margin: 10px; // Adjust margins for better spacing
//         // padding: 12px 10px; // Smaller padding for tablet screens
//         // font-size: 18px; // Smaller font size for better readability
//     }
// }

// .buy-credit-button {
//     width: 80%; // Increase or decrease based on desired button width
//     padding: 12px 10px; // Adjust padding for better hand reach and aesthetics
//     button {
//       width: 90%; // Matches the width of tab buttons
//       margin: 5px 0; // Adds vertical spacing between each button
//   }
// }

// .extra-credits button {
//     padding: 15px 10px; // Adjust padding to ensure buttons are not too large
//     font-size: 14px; // Adjust font size for clarity
// }
//     .payment-form-container {
//         background-color: #fff;

//         padding: 5vh;
//         border-radius: 8px;
//         box-shadow: 0 4px 8px rgba(0,0,0,0.1);
//         width: 30vh;
//         margin: auto;
//         margin-top:10vh;
//         font-family:Arial;
    
//         form {
//             display: flex;
//             flex-direction: column;
//         }
        
//         input[type="text"], input[type="radio"] {
//             margin-bottom: 10px;
//             padding: 12px;
//             border: 1px solid #b09999;
//             border-radius: 4px;
//         }
        
//         input[type="text"] {
//             width: 100%;
//         }
        
//         .card-details, .billing-address, .exp-cvc-group, .name-group {
//             margin-bottom: 20px;
//         }
        
//         .exp-cvc-group input, .name-group input {
//             width: calc(33.333% - 6px);
//             display: inline-block;
//             margin-right: 10px;
//         }
        
//         .exp-cvc-group input:last-child, .name-group input:last-child {
//             margin-right: 0;
//         }
        
//         label {
//             display: block;
//             margin-bottom: 5px;
//             font-size: 14px;
//             color: #333;
//         }
   
      
//         /* Hide the default Stripe CardElement styling */
//         .StripeElement {
//             width: 100%;
//             padding: 12px;
//             border: 1px solid #ccc;
//             border-radius: 4px;
//             box-sizing: border-box;
//         }
        
//         .StripeElement--focus {
//             border-color: #0061F2;
//         }
        
//     }


// }


   