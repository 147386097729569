@import "../../css/constants";

.profile-background {
    background: #ffffff ;
    // background: #171c3e;

    min-height: 70vh;
    overflow-x: hidden;
    // overflow: auto;
  
    .wait-list-text p {
      position: absolute; /* Change to absolute */
      top: 50%; /* Change from bottom to top */
      left: 50%;
      transform: translate(-50%, -50%) scale(0.8);
      font-size: 25px;
      font-family: "Arial";
      text-align: center;
      color: #ffffff;
    }
  
    .summary-form-container {
      margin-top: 5vh;
      max-width: 1400px;
      //height: 400vh;
      min-height: 200px;
      overflow-y: auto;
      margin-left: auto; /* Centers the container horizontally */
      margin-right: auto;
  
      p {
        color: #bdbdbd;
      }
  
      .summary-container {
        margin: 10px;
        margin-top: auto;
        margin-bottom: 10px;
        border-radius: 5px;
        padding: 30px;
        // background-color: #1e2246;
        background-color: map-get($colors, warm-background);
  
        .summary-header {
          cursor: pointer;
          font-weight: bold;
          font-family: "Arial";
        }
      }
      .summary-text {
        font-size: 16px;
        margin-top: 2vh;
        margin-left: 2vh;
        font-family: $primary-font-family;
        color: map-get($colors, text-color);
        line-height: 1.6;
      }

   
  
      .summary-time {
        color: map-get($colors, text-color);
        font-family: $primary-font-family;
        font-weight: 300;
      }
  
      p {
        font-size: 20px;
        margin-left: 75vh;

      }
    }
  
    .summary-btn-container {
      display: flex;
      justify-content: center;
      // align-items: left;
      gap: 50px;
      // margin-right: 700px;
      // margin-top: 50px;
  
      .summary-btn {
        margin-top: 100px;
        background: map-get($colors, text-white);
        color: map-get($colors, text-grey);
        font-weight: bold;
        cursor: pointer;
        transition: background 0.23s;
        height: 45px;
        padding: 10px 20px;
        border-radius: 50px;
        border: none;
        box-shadow: none;
        font-family: "Arial";
      }
    }
  }

  .conversation-text {
    
    margin-top: 2vh;
  
    // min-width: 600px;
    // margin-left: 50px;
    font-family: $primary-font-family;
    // color: #cac8c8;
    line-height: 1.6;
    color: map-get($colors, text-color);
    // text-align: center;


  
  }

  .conversation-human{
    color: map-get($colors, text-color) !important;
    font-size: 14px !important;

  }

  .conversation-ai{

    font-size: 14px !important;
    color: map-get($colors, text-color) !important;

  }
  .modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    
  
    .modal-content {
      padding: 24px; /* Slightly larger padding for better spacing */
      border-radius: 12px; /* Rounded corners for a modern look */
      box-shadow: 0 8px 24px rgba(0, 0, 0, 0.2); /* More prominent shadow for depth */
      text-align: center;
      width: 90%;
      max-width: 360px; /* Refined max width for better responsiveness */
      font-family: $primary-font-family; /* Use a clean, modern font */
      font-size: 16px; /* Standard font size for readability */
      color: #3d3d3d; /* Dark gray text for better readability */
      animation: scaleIn 0.3s ease-in-out; /* Scale-in animation for smooth appearance */

    
      // padding: 20px;
      // background-color: #1e2246;
      // color: #bdbdbd;
      // border-radius: 10px;
      // box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    }
  }
  .credits-tab {
    // position: relative;
    // margin-top: 20px;

    padding: 10px;


  
    .font {
      color: #323232;
      font-size: 16px;
      line-height: 5vh;
      font-family: "Helvetica";
    }
  
  }

  .error-message {
    color: red;
    font-weight: bold;
    margin-top: 20px;
    text-align: center;
  }
    

  .personality-profile-page {
  padding: 20px;
  max-width: 800px;
  margin: 0 auto;

  .chart-container {
    width: 100%;
    max-width: 500px;
    margin: 0 auto 40px;
  }

  .trait-descriptions {
    .trait {
      margin-bottom: 20px;

      h3 {
        font-size: 1.5em;
        margin-bottom: 10px;
      }

      p {
        font-size: 1em;
        line-height: 1.6;
      }
    }
  }
}

@media only screen and (max-width: 1024px) {
      .credits-tab {
        margin-left: 5vw; /* Adjust left margin on smaller screens */
        margin-right: 10vw; /* Adjust right margin on smaller screens */
        font-size: 16px; /* Smaller font size for smaller screens */
      }


  .conversation-text {
    
    margin-top: 2vh;
  
    // min-width: 600px;
    margin-left: -600px;
    font-family: $primary-font-family;
    // color: #cac8c8;
    line-height: 1.6;
    color: map-get($colors, text-color);
    font-size: 16px;



  
  }

      .summary-container {
        // margin: 10px;
        margin-top: auto;
        margin-bottom: 10px;
        border-radius: 5px;

        max-width: 600px !important;  
        // background-color: #1e2246;
        background-color: map-get($colors, warm-background);
        overflow-x: hidden;
  
        .summary-header {
          cursor: pointer;
          font-weight: bold;
          font-family: $primary-font-family;
        }
      }

      .summary-form-container {
        margin-top: 5vh;
        // max-width: 140px;
        //height: 400vh;
        min-height: 200px;
        overflow-y: auto;
        margin-left: auto; /* Centers the container horizontally */
        margin-right: auto;
      }


      .conversation-human{
        color: map-get($colors, text-color) !important;
        font-size: 14px !important;
        padding-right: 20px;
    
      }
    
      .conversation-ai{
    
        font-size: 14px !important;
        color: map-get($colors, text-color) !important;
    
      }
    

      .profile-background {
        background: #ffffff ;
        // background: #171c3e;
    
        min-height: 70vh;
        overflow-x: hidden;
        // overflow: auto;

      }
   

    
    }
  
  