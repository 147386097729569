$headerHeight: 58px;

.standard-page {
  height: 100vh;
  overflow: hidden;

  display: flex;
  flex-flow: column;


  .header {
    width: 100%;
    height: $headerHeight;
    overflow: hidden;
  }

  .content {
    flex: 1;
    height: calc(100vh - #{$headerHeight});
    overflow-y: scroll;
    overflow-x: hidden;
    background: #ffffff;
    color: #222222;

    // 1) Add consistent padding here:
 
  }
}