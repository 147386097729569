
/*-------------- resource bank style -------------*/
.resource-page {
  padding-left: 20em;
  padding-right: 20em;
  margin-top: 10vh;
}

.masthead-heading {
  margin-top: -0.2em;
  color: #e9e1d6;
  font-family: "Verdana";
  font-style: normal;
  font-weight: bold;
  font-size: 3em;
  letter-spacing: -0.02em;
  text-transform: uppercase;
  margin-left: 80px;
  margin-right: 80px;
}

images .inlineImage {
  float: right;
  padding: 20px;
  margin-left: 15px;
  margin-bottom: 15px;
  width: 45%;
  height: auto;
}

.paragraph-bg-1 {
  background-color: #ffffff;
  padding: 25px;
  border-radius: 30px;
}

.paragraph-bg-2 {
  padding: 25px;
  border-radius: 30px;
  background-image: url("../assets/bedroom.jpg");
}


@media only screen and (max-width: 1024px) {

  .resource-page {
    padding-left: 20em;
    padding-right: 20em;
    margin-top: 10vh;
  }
  
  



}