@import "../../css/constants";

.team_image {
  float: left;
  margin-right: 20px;
}

.research-page-body {
  height: 100%;
  margin: 0;
  padding: 0;
  overflow: hidden;
  font-family: $primary-font-family;

}

/*-------------- footer style -----------------*/

.intro-container {
  min-height: 10vh;
  display: flex;
  margin: 0 auto;
  width: 80%;
  flex-direction: column;
  padding-top: 10px;
  flex: 1;
  //height: calc(100vh - 60px);  // Fill remaining viewport height
  overflow-y: auto;  // Enable vertical scrolling
  margin-bottom: 20px;
  

  .introduction {
    font-family: $primary-font-family;
    // font-size: 18px ;
 
  

    .subpage-heading {
      color: map-get($colors, title-green);
      margin-bottom: 30px;
      margin-top: 50px;
      font-family: $primary-font-family;
      font-weight: 500;
    }

   p {
      margin-bottom: 20px;
      margin-top: 20px;
      line-height: 1.6;
      font-family: $primary-font-family;
      font-weight: 300;
      font-size: 18px;

    }

   
  }
}

.social-link {
  color: map-get($colors, title-green);
  text-decoration: none;
  font-weight: bold;
}




@media only screen and (max-width: 1024px) {

  .intro-container {
    min-height: 10vh;
    display: flex;
    margin: 0 auto;
    width: 100%;
    flex-direction: column;
    padding-top: 10px;
    flex: 1;
    //height: calc(100vh - 60px);  // Fill remaining viewport height
    overflow-y: auto;  // Enable vertical scrolling
    margin-bottom: 20px;
   
    
  
    .introduction {
      font-family: $primary-font-family;
      font-size: 20px !important;
 
   
    
  
      .subpage-heading {
        color: map-get($colors, title-green);
        margin-bottom: 30px;
        margin-top: 50px;
        font-family: $primary-font-family;
        font-weight: 500;
      }
  
     p {
        margin-bottom: 20px;
        margin-top: 20px;
        line-height: 1.6;
        font-family: $primary-font-family;
        font-weight: 300;
        font-size: 18px;
       
  
      }
  
     
    }
  }


}