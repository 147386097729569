//pw reset
@import "../../css/constants.scss";

.page-title {
    margin-bottom: 30px; /* Increase the bottom margin of the title */
    color: white;
  }
  .reset-password-body{

    background-color: map-get($colors, background-blue);
    min-height: 100vh;

  }
  .reset-form-container {
    margin: 0 auto;
    padding: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    background-color: map-get($colors, background-blue);
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    font-family: Arial;
  
    .form-container form {
      display: flex;
      flex-direction: column;
    }
  
    .input-field {
      margin-bottom: 20px;
      padding: 10px;
      font-size: 16px;
      // border: 1px solid #ccc;
      border-radius: 10px;
      border:none;
      background: map-get($colors, textbox-white-light);
      color: #ddd;

      &::placeholder {
        color: rgba(253, 253, 253, 0.5);
        opacity: 1;
      }

      
    }

    .input-field:focus {
      outline: 2px solid rgba(255, 255, 255, 0.5);
      outline-offset: 2px;
    }
  
    .submit-button {
      margin-left: 2vh;
      padding: 10px 20px;
      font-size: 16px;
      background-color: map-get($colors, textbox-white);
      color: map-get($colors, button-text-grey);
      border: none;
      border-radius: 50px;
      cursor: pointer;
    }
  
    .submit-button:hover {
      background-color: white;
    }
  
    .submit-button:disabled {
      background-color: #aec2e4; /* Lighter shade of the primary color */
      cursor: not-allowed; /* Indicates that the element is not interactive */
      opacity: 0.6;
    }
  
    .message {
      margin-top: 20px;
      font-size: 14px;
      color: #c7c4c4;
      font-size: 18px;
    }
  }
  
  .reset-password-container {
    margin: 20px auto;
    padding: 20px;
    // border: 1px solid #ddd;
    border-radius: 10px;
    background-color: #1e2246;
    display: flex;
    min-height: 80vh;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    font-family: Arial;
    color: #c7c4c4;
    font-family: $primary-font-family;
  
    .reset-password-container h2 {
      text-align: center;
      margin-bottom: 20px;
    }
  
    .reset-password-form {
      display: flex;
      flex-direction: column;
    }
  
    .reset-password-form div {
      margin-bottom: 15px;
    }
  
    .reset-password-form label {
      display: block;
      margin-bottom: 5px;
    }
  
    .reset-password-form input {
      width: 100%;
      padding: 8px;
      // border: 1px solid #ccc;
      border: none;
      border-radius: 10px;
      background-color:  map-get($colors, textbox-white-light);
    }
  
    .reset-password-form button {
      cursor: pointer;
      width: 100%;
      padding: 10px;
      background-color:  map-get($colors, textbox-white);
      color: map-get($colors, button-text-grey);
      border: none;
      border-radius: 50px;
      transition: background-color 0.3s ease;
    }
  
    .reset-password-form button:hover {
      background-color: #ffffff;
    }
  
    .message {
      color: #d1d0d0; /* Default text color */
      margin-top: 3vh; /* Spacing below the message */
      font-size: 1rem; /* Default font size */
    }
  
    .error {
      color: #dadada; /* Error color, often a shade of red */
      font-weight: bold; /* Makes the error message more prominent */
    }
  }
  
  .centered-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed; /* Use fixed to make sure it covers the entire viewport */
    top: 0;
    left: 0;
    height: 100vh; /* Full viewport height */
    width: 100%; /* Full viewport width */
    background-color: rgba(
      0,
      0,
      0,
      0.8
    ); /* Optional: background color with opacity */
    z-index: 1000; /* High z-index to make sure it's on top of other content */
  }
  
  .block-message {
    text-align: center; /* Center text within the block */
    width: 90%; /* Set a max width to the inner content */
    max-width: 600px; /* Optional: Constrain max width for better readability */
    padding: 20px; /* Padding for aesthetics */
    background: white; /* Background of the message, contrast with overlay */
    border-radius: 8px; /* Optional: rounded corners */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Optional: shadow for visual depth */
  }
  
  .block-message p {
    margin: 16px 0; /* Space out paragraphs */
  }
  
  
  .loading-popup {
    position: fixed; // Position fixed to cover the whole screen
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5); // Semi-transparent black background
  
    .loading-content {
      padding: 20px 40px;
      background: white;
      border-radius: 10px;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
      text-align: center;
  
      p {
        margin: 0;
        font-size: 16px;
        color: #333;
      }
    }
  }
  